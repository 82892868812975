.box-header {
    z-index: 3;
    background: $color-bg-lightblue;

    &::after {
        content: '';
        height: 5rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: $color-bg-body;
    }

    .topic-intro-box {
        position: relative;
        left: auto;
        width: 32rem;

        @media (max-width: 560px) {
            width: auto;
        }
    }
    &__image {
        position: absolute;
        left: 26.6rem;
        top: 0.25rem;
        right: 0;
        z-index: 3;
        background-size: 42rem;
        bottom: -10rem;
        background-repeat: no-repeat;        
    }
}

.box-header + .preview-list {
    margin-top: 0;
}