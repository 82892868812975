.press-header {

  grid-template-areas: 
    'visual list list' 
    'summary summary space'
    'link link link'
  ;
  grid-template-columns: 2fr 1fr 6rem;
  grid-gap: 2rem;
  grid-column-gap: 0;
  padding: 0 2rem;

  @media (max-width: 850px) {
    grid-template-areas: 
      'visual' 
      'summary'
      'link'
      'list'
    ;
    grid-template-columns: 1fr;
  }

  @media (max-width: 520px) {

    padding: 0 1rem;
  }

  &__image {
    grid-area: visual;
    position: relative;

    img {
      width: 100%;
      height: auto;
      clip-path: polygon(0 0, 100% 0%, 100% 93.5%, 0% 100%);
    }
  }


  &__mask {
    grid-area: list;
    background: $color-bg-body;
    height: 30%;
    mask-image: linear-gradient(to bottom, transparent 25%, black 75%);
    pointer-events: none;
    align-self: end;
  }  

  &__list {
    grid-area: list;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      min-height: 20rem;
      max-height: 50rem;
      overflow: auto;
      padding-right: 1rem;
      position: relative;

      @media (max-width: 850px) {
        min-height: 0;
        max-height: none;
        height: 20rem !important;
      }

      li {

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }  

  &__button {
      
    appearance: none;
    border: none;
    background: none;
    text-align: left;
    font-family: gesta, sans-serif;
    color: $color-text;

    display: grid;

    cursor: pointer;

    grid-template-areas: 
      'headline visual' 
      'date visual'
    ;


    grid-gap: 1rem;

    grid-template-columns: 1fr 0.33333fr;
    grid-template-rows: auto 1fr;

    padding: 1rem 1rem 1rem 2rem;

    @media (max-width: 850px) {
      padding: 1rem 0;
    }


    &--active {

      background: linear-gradient(to right, rgba(#000, .05), rgba(#000, 0) 60%);
    }

    h3 {
      grid-area: headline;
      font-size: 1.125rem;
      margin: .25rem 0 0;
      font-weight: 500;
    }

    img {
      display: block;
      grid-area: visual;
      clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
      width: 100%;
    }

    span {
      grid-area: date;
      color: $color-text-brand;
      align-self: top;
      padding-bottom: .25rem;
      font-family: GestaSemiCondensed-Regular;
    }
    
  }  

  &__summary {
    grid-area: summary;
    p {
      line-height: 1.375;
    }
  }

  &-box {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: $color-bg-gunmetal;
    color: #FFF;
    width: 28rem;
    box-sizing: border-box;
    padding: 2rem 2rem 3rem;
    clip-path: polygon(0 0, 100% 0, 100% 93.5%, 0% 100%);

    @media (max-width: 850px) {
      position: static;
      clip-path: none;
      width: auto;
      padding: 1rem;
    }

    .press-header-box__title {
      font-family: GestaSemiCondensed-Light, gesta, sans-serif;
      font-size: 1.5rem;
      font-weight: 300;
      letter-spacing: 0;
    }
  } 

  &__link {
    grid-area: link;

    a {
      display: inline-block;
      background: $color-bg-gunmetal;
      padding: .5rem;
      font-family: Klavika;
      color: #FFF;
      text-decoration: none;
      letter-spacing: 0.025rem;
      margin-right: .25rem;
    }
  }
}

.press-release {


    margin-top: 2rem;
    padding: 0 2rem;

    @media (max-width: 520px) {
      padding: 0 1rem;
      margin-top: 0;
    }

    &__published {
        font-weight: normal;
        font-size: 1rem;
        text-transform: none !important;
    }

    &__title {
        font-weight: 500 !important;
        letter-spacing: 0.04rem !important;
        margin-top: .75rem !important;
    }

    &__content {
        margin-top: 2rem;

    }
}

.article-block--files {
    margin-top: 2rem;
    border-top: 1px solid #8B9FAA;
    border-bottom: 1px solid #8B9FAA;

    padding: 4rem 2rem 0;

    .press-release__title {
        margin: 0;
        text-transform: uppercase;
        font-size: 2rem;
        font-family: Klavika;
    }
}


.article-files {
    overflow: hidden;
    margin-top: 2rem;

    .splide {
        padding-bottom: 4rem;
    }

    .splide__slide {
        img {
            display: block;
            max-width: 100%;
            clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
        }
    }

    .splide__arrow {
        top: 100%;
        transform: translateY(-3rem);
        
        opacity: 1;
        height: 2rem;
        width: 2rem;
        background: $color-bg-body;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25), -2px -2px 2px white;
    
        &--next {
          right: .25rem;
        }
    
        &--prev {
          left: auto;
          right: 3rem;
        }
    
        &:disabled {
          opacity: 0;
        }
    
        svg {
          path {
            fill: #AABECA;
          }
        }
      }    
}