@keyframes breathing {
  0% {
    transform: scale(.75);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}
