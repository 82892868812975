.header {
  padding-top: 0;
  padding-bottom: 2rem;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 2rem;
  }

  &__logo {
    order: 2;
    margin: 0;

    img {
      width: scale-size(20rem);
      height: auto;
    }
  }

  &__mobile {
    &-nav {


      @media (min-width: 1241px) {
        display: none !important;      
      }

      .header-toggle {
        max-height: 0;
        transition: max-height .25s linear;
        box-sizing: border-box;
        overflow: hidden;

        &--open {
          display: block;
          max-height: 100vh;
        }

      }

    }
  }

  &-breadcrumbs {
    background: #FFF;
    padding: 1rem 0;
    font-size: .875rem;
    

    @media (max-width: 520px) {
      display: none;      
    }

    &__inner {
      display: flex;
    }


    &__item, &__h1 {
      text-decoration: none;
      color: #1b3e51;
      display: inline-block;
    
      &--current {
        color: $color-text-brand;
      }

      &::after {
        content: '›';
        display: inline-block;
        margin-left: .5rem;
        margin-right: .5rem;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }

    &__h1 {
      color: $color-text-brand;
      font-size: .875rem;
      margin: 0;
      font-weight: normal;
    }    

    &__livestream {
      display: none;
      margin-left: auto;
      margin-right: 0;
      text-decoration: none;
      color: $color-text-nav;
      font-weight: bold;
      position: relative;
      text-transform: uppercase;
      font-family: Klavika, sans-serif;

      &::before {
        content: '';
        position: absolute;
        width: .375rem;
        height: .375rem;
        border-radius: 50%;
        background: $color-text-brand;
        margin-right: 1rem;
        left: -.75rem;
        top: .25rem;
        animation: fadeIn 1s infinite alternate;
      }

      @keyframes fadeIn { 
        from { opacity: 0; } 
      }      

      &--on {
        display: block;
      }
    }
  }



  &-nav {
    order: 1;
    display: flex;
    align-items: flex-start;

    &__toggle {
      position: relative;
      appearance: none;
      display: block;
      background: none;
      border: 0;
      cursor: pointer;
      padding: 0;
      width: scale-size(5rem);
      height: scale-size(4rem);

      @media (max-width: 640px) {

        transform: scale(0.75) translateY(.25rem);
        
      }

      img {
        width: scale-size(4rem);
        height: auto;
      }
    }

    &__meta {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      @media (max-width: 1240px) {
        display: none;      
      }


      a {
        color: $color-text-nav;
        display: inline-block;
        margin-left: scale-size(8rem);
        font-size: .75rem;
        text-decoration: none;
        text-transform: uppercase;
        font-family: Klavika, sans-serif;
      }
    }

  }

  &-map {
    height: 0;
    padding-top: 2rem;
    position: relative;
    z-index: 5;
    transition: height .25s ease-in;

    @media (max-width: 1240px) {
      display: none !important;      
    }


    &__inner {
      position: relative;
      top: -50rem;
      transition: top .25s linear, opacity .325s linear;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      opacity: 0;
    }

    &__label {
      position: absolute;
      z-index: 2;
      font-family: AkkoRoundedPro, sans-serif;
      font-weight: 500;
      color: #647883;
      font-size: 1.125rem;
      text-transform: uppercase;
      text-shadow:  0 0 .5rem #FFF, 0 0 1rem #FFF, 0 0 2rem #FFF;
      text-align: center;
      pointer-events: none;
      transition: opacity .25s linear;
      letter-spacing: 0.04rem;
      margin: 1.5rem 0;

      &--visible {
        opacity: 1;
      }
    }

    &__nav {
      position: relative;
      max-width: 14rem;
      line-height: 1.5;
      opacity: 0;
      transition: opacity .25s linear;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 14rem;
      font-family: Klavika, sans-serif;

      img {
        width: 3rem;
      }

      h2 {
        color: #D51317;
        font-family: Klavika, sans-serif;
        font-weight: 500;
        margin: 1rem 0 1rem;
        letter-spacing: .04rem;
        line-height: 1.25;
        font-size: 1.75rem;
        text-transform: uppercase;

      }

      &--leaving {
        opacity: 0 !important;
      }

      &-item {
        &--current {
          a {
            font-weight: bold;
          }

        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        min-height: 7rem;

      }

      li {
        margin-top: .25rem;
      }

      a {
    
        color: $color-text-muted;
        text-decoration: none;
        ;
      }
    }

    &__nav-info {



      p {
        margin: 1rem 0 0;
        color: $color-text-muted
      }

      &--external {
        p {
          text-transform: uppercase;
          margin: 0;
        }

        margin-bottom: 1rem;
      }
    }
  

    &__nav-item {

      &--external {
          position: relative;
          padding-left: 1.5rem;
          background: url(../../images/link-ext.svg) no-repeat;
          background-size: 1.25rem;
          background-position: 0 -0.2rem;
      }
    }

    &__shadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      opacity: .25;
    }

    &__visual {
      position: relative;

      @media (max-width: 1240px) {
        display: none !important;      
      }

      svg {
        width: scale-size(92rem);
        position: relative;
        z-index: 2;

        #plan {
          opacity: 1;
        }
        g {
          cursor: pointer;
          opacity: 0;
          transition: opacity linear .25s;
          &:hover:not(.visible) {
            //opacity: .25;
          }
        }
        g.visible {
          opacity: 1;
        }
      }
    }

    &.header-toggle--open {
      height: 30rem;
      overflow: visible;

      .header-map__inner {
        top: 0;
        opacity: 1;
      }

      .header-map__nav {
        opacity: 1;
        transition: opacity .25s linear;
      }
    }
    
    &__subnavs {
      display: none;
    }

  }

}

.page--newsroom, .page--newsroom-archive, .page--events, .header--increased-height {
  .header-map.header-toggle--open {
    height: 30rem;
  }
}

.page--newsroom-archive .newsroom-search {
  padding-top: 0;
}


#label-buehne {
  left: -2%;
  width: 25%;
  top: 11%;
}

#label-mediathek {
  left: 28%;
  width: 18%;
  top: 14%;
}

#label-knowledge {
  left: 38%;
  width: 13%;
  top: -6%;
}

#label-themen-hub {
  left: 52%;
  width: 10%;
  top: 29%;
}

#label-newsroom {
  left: 59.5%;
  width: 15%;
  top: 12.5%;
}

#label-showrooms {
  left: 69%;
  width: 15%;
  top: 47%;
}

#label-online-planer {
  left: 44%;
  width: 8%;
  top: 71%;
}

#label-my-archive {
  left: 31%;
  width: 8%;
  top: 46%;
}

#label-festival {
  left: 8.5%;
  width: 20%;
  top: 38%;
}

.mobile-nav {

  padding-top: 2rem;

  &__inner {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin-right: -2rem;
    padding-right: 2rem;

    @media (max-width: 520px) {
      margin-right: -1rem;
      padding-right: 1rem;
    }
    
    &::after {
      position: absolute;
      content: '';
      background: url(../../images/plan-footer.svg);
      background-size: contain;
      left: 15rem;
      right: -200%;
      top: 0;
      bottom: -50%;
      opacity: .5;
      pointer-events: none;
      z-index: 1;
      filter: brightness(75%);
    }

  }

  .header-toggle {

    max-width: 18rem;
    position: relative;
    z-index: 2;
  }

  &__item {

    font-family: Klavika;
    text-transform: uppercase;
    letter-spacing: .025rem;
    display: block;
    font-size: 1.5rem;
    border-bottom: 1px solid $color-text-window-gray;
    color: $color-text-mobile-nav;
    padding: .5rem 2rem;
    padding-right: 1rem;
    font-weight: 300;
    text-decoration: none;
    box-sizing: border-box;

    background: url(../../images/nav-chevron.svg) no-repeat;
    background-size: 1rem 1rem;
    background-position: left center;


  }

  .header-nav__meta {

    margin-left: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    position: relative;
    z-index: 2;
    

    @media (max-width: 620px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 0;

      a {
        padding: .5rem 0;
      }

      .header-nav__meta-item--contact {
        display: none;
      }

    }

    a {
      display: inline-block;
      margin: 0;
      font-size: .875rem;
      white-space: nowrap;
    }
  }

}

.map-modal {
  position: absolute;
  left: 0;
  top: 0rem;
  width: 35rem;
  z-index: 3;
  filter: drop-shadow(0.125rem 0.25rem 1.5rem rgba(0, 0, 0, 0.225));


  &__inner {
    padding: 2rem 2rem 4rem;
    background: rgba(#E8E9E6, .95);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), 0% 100%);
  }

  &__info {
    font-size: 1rem;
    color: $color-text-muted;
    font-family: Klavika;
    font-weight: normal;
    letter-spacing: .025rem;
    margin: 1rem 0 !important;
    text-transform: uppercase;
    
  }

  &__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 2rem;
  }

  &__link {

  }

  &__link-img {
    display: block;
    width: 100% !important;
  }

  .map-modal__logo {
    width: 24rem;
  }
}