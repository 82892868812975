.contact-list {

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11rem, 14fr));
  gap: 2rem;
  margin-top: 4rem;

  &-item {

    &__portrait {
      clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
      overflow: hidden;
      img {
        width: 100%;
        display: block;
      }
    }

    &__copy {

      line-height: 1.25;

      h4 {
        font-size: 1.125rem;
        margin: 1rem 0 0;
      }

      p {
        margin: .25rem 0 0;
      }

      a {
        display: block;
        color: $color-text-brand;
        text-decoration: none;
        font-size: 1rem;
      }
    }

  }
}