.article {

  &-header {
    display: grid;
    max-width: scale-size(116rem);
    margin: auto;

    grid-gap: 2rem;
    grid-template-areas:
              'visual tagline'
              'visual headline'
              'visual meta'
    ;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto 1fr;
    grid-row-gap: 1.5rem;

    @media (max-width: 870px) {
      grid-template-areas:
        'visual'
        'tagline'
        'headline'
        'meta'
      ;

      grid-template-columns: 1fr;

      & > *:not(.article-header__visual) {
        margin: 0 2rem;

        @media (max-width: 520px) {
          margin: 0 1rem;
        }
      }
    
    }

    &__tagline {
      grid-area: tagline;
      font-size: 1rem;
      margin: 0;
      color: $color-text-headline;
      font-weight: normal;
      font-family: Klavika, sans-serif;
      text-transform: uppercase;
      margin-top: 1.5rem;
      letter-spacing: .0375rem;

      span {
        color: $color-text-muted;
      }

      &--powered-by {
        margin-top: .25rem;
        padding-top: .75rem;
        padding-bottom: .75rem;
        background-size: contain;
        background: url(../../images/powered_by.svg) no-repeat right center;
      }
    }

    &__headline {
      grid-area: headline;
      font-weight: normal;
      font-size: 2rem;
      margin: 0;
      line-height: 1.25;
      font-family: Gesta, sans-serif;

      @media (max-width: 860px) {
        br  {
          display: none;
        }
      }
    }

    &__meta {
      grid-area: meta;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 1rem;
      padding-bottom: 1.5rem;

      b {
        font-weight: 500;
      }

      &-top {
        display: flex;
        align-items: center;

        @media (max-width: 1090px) {
          display: block;

          span {
            display: none;
          }
          time {
            margin-top: 1rem;
            display: block;
          }
        }    

        @media (max-width: 860px) {
          display: inline-block;

          span {
            display: inline;
          }
          time {
            margin: 0;
            display: inline;
          }
        }  

        & > * {
          margin-right: .5rem;
        }
      }

      img {
        width: 3rem;
        border-radius: 50%;
        display: inline-block;
      }


    }

    &__reading-time {
      margin-top: 1rem;
      color: $color-text-muted;
      display: flex;
      align-items: center;

      @media (max-width: 860px) { 
        display: none;
      }

      svg {
        width: 3rem;
        margin-right: 1rem;
      }
    }

    &__visual {
      position: relative;
      grid-area: visual;
      clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
      overflow: hidden;
      
      img, source {
        width: 100%;
        display: block;
      }
    }

  }

  &-content {
    margin-top: 2rem;
  }

  &-image {
    width: 100%;
    clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);

    &--no-crop {
      clip-path: none;
    }
  }

  &-block {
    display: grid;
    max-width: scale-size(116rem);
    margin: auto;

    &--headline {
      .article-block__text {
        padding: 1rem 0 !important;
        margin: 1rem 0 !important;
      }

      h2 {
        font-size: 1.5rem;
        margin: 0;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 0.025rem;
        line-height: 1.375;
        font-family: Klavika, sans-serif;
      }

    }

    &--border-top {

      .article-block__text, &.article-block-image {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: solid 1px $color-border-window-gray;
      
      }
      
    }

    &--border-bottom {
      .article-block__text, &.article-block-image {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: solid 1px $color-border-window-gray;
      }

      .article-block-image {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: solid 1px $color-border-window-gray;
      }
    }

    &--space-above {
      margin-top: 2rem;
    }

    &--space-above-sm {
      margin-top: 1rem;
    }


    &--space-below {
      margin-bottom: 2rem;
    }

    &--space-above-xl {
      margin-top: 4rem;
    }

    &--space-below-xl {
      margin-bottom: 4rem;
    }

    &[class*="space-above"] > .typography > *:first-child {
      margin-top: 0;
    }

    &--text {
      grid-gap: 3rem;
      grid-template-areas: 'space text space2';
      grid-template-columns: 15% 1fr 15%;

      @media (max-width: 1190px) {
        grid-gap: 2rem;
        grid-template-columns: 0rem 1fr 0rem;
      }    

      @media (max-width: 520px) {
        grid-gap: 1rem;
      } 
    }

    &--footer {
      border-top: 1px solid #6C7F87;
      padding-top: 2rem;
      margin-top: 2rem;
    }

    &--image-before-text {
      grid-template-areas: 'image text space';
      grid-template-columns: 1fr 1.125fr 15%;

      @media (max-width: 1090px) {
        grid-template-areas: 'space image space2' 'space text space2';
        grid-template-columns: 0 1fr 0;
        grid-gap: 2rem;
      }  

      @media (max-width: 520px) {
        grid-gap: 1rem;
      }  
    }

    &--image-after-text {
      grid-template-areas: 'space text image';
      grid-template-columns: 15% 1.125fr 1fr;

      @media (max-width: 1090px) {
        grid-template-areas: 'space text space2' 'space image space2';
        grid-template-columns: 0 1fr 0;
        grid-gap: 2rem;
      }  

      @media (max-width: 520px) {
        grid-gap: 1rem;
      }  
    }

    &--smaller-image {
      grid-template-columns: 28% 1fr 15%;
    }

    &--type {
      &-quote, &-info, &-definition {
        .article-block__text {
          font-style: italic;
          margin-left: 3rem;
        }
      }
    }

    &__text {
      grid-area: text;
      position: relative;
    }

    &__text-type {
      position: relative;
      margin-left: -3rem;
      top: .125rem;
      float: left;

      & + * {
        margin-top: 0 !important;
      }
    }

    &__image {
      grid-area: image;

      margin: 0;

      .plyr {
        clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
        transition: clip-path linear .25s;

        &--stopped.plyr--paused {
          .plyr__controls {
            opacity: 0;
          }
        }

        &--playing {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          clip-path: none;

          .plyr__controls {
            opacity: 1;
          }
        }


      }
    }

    &__video-caption {
      margin: 0.5rem 0 2rem;
      line-height: 1.5;
      font-size: 1rem;
      
      @media (max-width: 520px) {
        line-height: 1.375;
      }
    }

    &__video--button {
      appearance: none;
      background: none;
      display: block;
      border: 0;
      cursor: pointer;
      padding: 0;

      .article-image {
        position: relative;

        &::after {

          content: '';
          background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff))) no-repeat;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s;
          z-index: 2;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          opacity: .9;
          background-size: 1.125rem 1.125rem;
          background-position: 1.125rem center;
          background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 15 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(1,0,0,1,-2,-2.47272e-06)'%3E%3Cpath d='M15.562,8.1L3.87,0.225C3.052,-0.337 2,0.225 2,1.125L2,16.875C2,17.775 3.052,18.337 3.87,17.775L15.563,9.9C16.147,9.45 16.147,8.55 15.563,8.1L15.562,8.1Z' style='fill-rule:nonzero; fill: %23FFF'/%3E%3C/g%3E%3C/svg%3E%0A");

        }
      }
    }


    &-link {
      padding: 3rem 0;
      margin-bottom: 3rem;

      border-bottom: 1px solid $color-bg-line;
      padding-bottom: .5rem;
      display: block;

      &:before {
        display: block;
        height: 0;
        content: '';
        position: relative;
        top: 3rem;
        border-top: 1px solid $color-bg-line;
      }
      
      &__inner {
        display: grid;
        grid-column-gap: 3rem;
        grid-template-areas:
              'space headline space-end'
              'space link space-end'
        ;
        max-width: scale-size(116rem);
        margin: auto;
        grid-template-columns: 15% auto 15%;

        @media (max-width: 1190px) {
          grid-column-gap: 2rem;
          grid-template-columns: 0rem 1fr 0rem;
          grid-template-areas:
          'space headline space-end' 'space link space-end'
          ;
        }

        @media (max-width: 520px) {
          grid-column-gap: 1rem;
        }
      }

      h4 {
        grid-area: headline;
        text-transform: uppercase;
        font-family: Klavika, sans-serif;
        font-weight: 600;
      }
    }

    &__link {
      grid-area: link;
      display: grid;
      grid-gap: 3rem;
      grid-template-areas: 'image text';
      grid-template-columns: 6rem auto;
      text-decoration: none;
      color: $color-text;

      @media (max-width: 1190px) {
        grid-gap: 2rem;

      }

      @media (max-width: 520px) {
        grid-gap: 1rem;
      }

      b {
        font-weight: 500;
      }

      img {
        grid-area: image;
        width: 100%;
      }

      &-content {
        grid-area: text;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-tagline {
        color: $color-text-headline;
        font-weight: normal;
        font-family: Klavika, sans-serif;
        text-transform: uppercase;
        display: block;
        font-size: 1rem;

        span {
          color: $color-text-muted;
        }

      }
      &-headline {
        margin-top: .75rem;
        font-weight: 600;
        font-family: Gesta, sans-serif;
      }
    }


  }


  &-block-image {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

    figure {
      grid-area: auto;

    }

    figcaption {
      @media (max-width: 1280px) { 
        padding: 0 2rem;
      }

      @media (max-width: 520px) { 
        padding: 0 1rem;
      }
    }

    picture, img {

      max-width: 100%;
      display: block;
    }
  }

  &-block-video {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

    figure {
      grid-area: auto;

    }

    figcaption {
      @media (max-width: 1280px) { 
        padding: 0 2rem;
      }

      @media (max-width: 520px) { 
        padding: 0 1rem;
      }
    }

    picture, img {

      max-width: 100%;
      display: block;
    }    

  }

}
