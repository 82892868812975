.filter {

  display: flex;

  &__widget {

    margin-right: .5rem;

    input, select {

      appearance: none;
      font-family: Gesta, sans-serif;
      text-transform: lowercase;
      background: transparent;
      border: 1px solid $color-border-form;

      padding: .5rem;
      height: auto;
      display: inline-block;
      border-radius: .25rem;
    }

    select {
      padding-right: 1rem;
    }

    &--select {
      position: relative;


      svg {
        position: absolute;
        width: .5rem;
        right: .5rem;
        top: 0.125rem;
      }
    }
  }
}

.FormBuilderViewport {
  width: 100%;
  background: #DFEAEA;
}
