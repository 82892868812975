.page-newsroom-archive {
    background: #EFF4F6;
}

.newsroom--archive {
    .newsroom__inner {
        @media (max-width: 840px) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .newsroom__articles {

        @media (max-width: 840px) {
            padding-bottom: 4rem;
        }        
    }
}

.article-list {
    background: #FFF;
    border: 1px $color-border-window-gray solid;
    padding: 0 1rem;
    position: relative;
    z-index: 2;

    & > p > a {
        color: $color-text-brand;
    }
}

.article-preview {
    padding: 1rem .5rem;
    border-bottom: 1px solid #748A96;
    display: block;
    text-decoration: none;
    color: $color-text;
    line-height: 1.25;

    &__inner {
        display: grid;
        grid-template-areas: 'content image' 'meta image';
        grid-template-columns: 3fr 1fr;
        grid-gap: 1.5rem;

        @media (max-width: 600px) {
            grid-template-areas: 'content image' 'meta meta';
            grid-gap: 1rem;
        }
    }

    &__content {
        grid-area: content;
    }

    &__image {
        grid-area: image;

        img {
            display: block;
            width: 100%;
            clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
        }
    }

    &__author {
        font-weight: 500;
    }

    b {
        color: #DA2028;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 0.875rem;
        margin: 0;
        font-family: Klavika, sans-serif;    
        letter-spacing: .0375rem;    
        span {
            color: $color-text-muted;
        }
    }

    h3 {
        font-weight: normal;
        margin: .5rem 0 0;
        font-size: 1.25rem;
        font-weight: 500;
    }

    &:last-child {
        border-bottom: none;
    }

    &__meta {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        grid-area: meta;

        img, svg {
            width: 2rem;
            height: 2rem;
        }
    }

    &__meta-start {
        display: flex;
        align-items: center;

        b {
            margin-left: .5rem;
            color: $color-text-muted;
        }

        span {
            display: inline-block;
            margin: 0 .5rem 0;
            color: $color-text-muted;
        }

    }

    &__author {

    }

    &__reading-time {
        display: flex;
        align-items: center;
        color: $color-text-muted;
        svg {
            margin-right: 0.5rem;
        }

        @media (max-width: 660px) {
            display: none;
        }
        
    }
    
}

.newsroom {
    &-search {
        padding-top: 2.75rem;
        padding-left: 1rem;

        @media (max-width: 840px) {
            padding: 0;
            margin-bottom: 2rem;
        }

        h2 {
            
            color: $color-text-headline;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 1.5rem;
            margin: 0;
            font-family: Klavika, sans-serif;                
        }

        &__input {
            padding: .25rem 1rem;
            font-family: gesta, sans-serif;
            font-size: 1rem;
            display: block;
            margin-top: 1rem;
            box-sizing: border-box;
            width: 100%;
            
        }

        &__form { 
            position: relative;
            
            &--filled .newsroom-search__clear {
                opacity: 1;
            }

        }

        &__clear {
            appearance: none;
            color: $color-text-muted;
            font-size: 1rem;
            line-height: 1rem;
            position: absolute;
            right: 0rem;
            padding-right: .5rem;
            top: 0rem;
            bottom: 0;
            line-height: 100%;
            appearance: none;
            background: none;
            border: none;
            opacity: 0;
            z-index: 2;
          }
    }
}

.autocomplete {
    font-family: Gesta, sans-serif;
    font-size: 1rem;

    & > div {
        padding: .25rem 1rem;
        color: $color-text;
    }

    & > div.selected {
        background: $color-text-brand;
        color: #FFF;
    }

    & > div:hover:not(.group) {
        background: #EEE;
        color: $color-text;
    }
}