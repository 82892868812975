.button {
  appearance: none;
  display: inline-block;
  background: rgb(250, 252, 252);
  border-radius: 5rem;
  font-size: .9375rem;
  padding: 1.375rem 1.5rem 1.125rem;
  text-transform: uppercase;

  box-shadow: 
    -2px -2px 6px rgba(#FFF, 1),
    4px 4px 10px desaturate(rgba(98, 133, 149, .5), 5%);
  letter-spacing: .04rem;
  text-decoration: none;
  color: rgb(98, 133, 149);;
  transition: background-color .25s linear;
  
  font-family: AkkoRoundedPro, sans-serif;
  cursor: pointer;
  white-space: nowrap;
  border: 0;

  @media (max-width: 640px) { 
    padding: calc(3/4 * 1.375rem) calc(3/4 * 1.5rem) calc(3/4 * 1.125rem);
    font-size: .875rem;
  }

  &:hover {
    color: darken(rgb(98, 133, 149), 10%);
    transition: color .25s linear, inherit;
    background-color: darken(rgba(250, 252, 252, 1), 2.5%);
  }

  &--small {
    padding: .75rem 1rem .5rem;
  }

  &--simple {
    box-shadow: 4px 4px 10px desaturate(rgba(98, 133, 149, .5), 5%);
  }

  &--image {
    background: none;
    box-shadow: none;
    width: 100%;
    border: 0;
    padding: 0;
    border-radius: 0;

    &:hover {
      background: none;
    }
  }

  &--overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    background: none;
    box-shadow: none;
    width: 100%;
    border: 0;
    padding: 0;
    border-radius: 0;

    &:hover {
      background: none;
    }
  }

  &--border {
    margin-top: 1rem;
    box-shadow: none;
    border-radius: 0;
    border: .5px solid $color-text;
    color: $color-text;
    background: transparent;
    padding: .6rem 1rem .4rem;
    &:hover {
      background-color: rgba(255,255,255,.25);
    }
  }

  &--wide {
      padding-left: 2rem;
      padding-right: 2rem;
  }

  &--white {
    background: #FFF;
  }

  &--tab {
    box-shadow: none;
    background: $color-bg-heather;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
    color: #FFF;
    border-radius: 1.5rem 1.5rem 0 0;
    font-size: 1.25rem;
    padding: 1rem 2rem 1.5rem;

    &:hover {
      color: #FFF;
      background: darken($color-bg-heather, 10%);
    }

  }

  &--level-up, &--download, &--basket-add, &--basket, &--back, &--icon {
    background-image: url('../../images/up.svg');
    background-repeat: no-repeat;
    background-position: 1.25rem center;
    padding-left: 3rem;
    background-size: 1.125rem;
  }

  &--back {
    background-image: url('../../images/direction-left.svg');
    background-size: 1.25rem;
  }

  &--download {
    background-image: url('../../images/download.svg');
  }

  &--basket {
    background-image: url('../../images/shopping-basket-3.svg');
    background-size: 1.375rem;
    padding-left: 3.125rem;
  }

  &--basket-add {
    background-image: url('../../images/shopping-basket-add.svg');
    background-size: 1.375rem;
    padding-left: 3.125rem;
  }

  &--search {
    background-image: url('../../images/Magnifying-Glass--Streamline-Flex.svg');
    background-size: 1.125rem;
    padding-left: 3.125rem;
    background-position: 1.25rem 1.375rem;
  }

  &--email {
    background-image: url('../../images/Sign-At--Streamline-Flex.svg');
    background-size: 1.125rem;
    padding-left: 3.125rem;
    //background-position: 1.25rem 1.375rem;
  }

  &--external {
    background-image: url('../../images/Link-Share-2--Streamline-Plump.svg');
    background-size: 1.125rem;
    padding-left: 3.125rem;
    //background-position: 1.25rem 1.375rem;
  }

  &--mobile {
    width: 100%;
    text-align: center;

  }

  &--hide-mobile {
    @media (max-width: 520px) {
      display: none !important;
    }
  }

  &-group {
    display: flex;
    margin-top: 2rem;

    &--right {
      text-align: right;
      flex-grow: 1;
      display: block;
    }

    &--justify {
        justify-content: space-between;
    }

    &--center {
      text-align: center;
      
      .button {
        margin: auto !important;
      }
    }

    &--mobile {
      display: none;
      padding: 1rem 1rem 2rem;

      .button {
        margin-top: 1rem;
        &:first-child {
          margin-top: 0;
        }
      }

      @media (max-width: 520px) {
        display: block;
        padding: 2rem 1rem 1rem;
      }
    }


    .button {
      margin-right: 2rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.showroom-thumbnails {
  & + .button-group--mobile {
    @media (max-width: 520px) {
      padding: 0 1rem 2rem;
    }
  }
}

.typography--light {
  .button--border {
    color: $color-text-light;
    border-color: $color-text-light;
  }
}

.nav-button {

  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;

  @media (max-width: 1280px) { 
    display: none;
  }

  &--brand {
    .nav-button {
      &__button {
        color: #FFF;
        background: $color-text-brand;


        &:hover {
          color: #FFF;
          transition: color .25s linear, inherit;
          background: darken($color-text-brand, 2.5%);
        }
      }
    }
  }

  &--festival {
    .nav-button {
      &__button {
        color: #FFF;
        background: rgb(183, 108, 179);

        &:hover {
          color: #FFF;
          transition: color .25s linear, inherit;
          background: darken(rgb(183, 108, 179) , 2.5%);
        }
      }
    }
  }

  &__inner {
    max-width: scale-size(132rem);
    margin: 0 auto;
    position: relative;
    text-align: center;
    line-height: 1;


    small {
      font-size: 1rem;
      display: block;
      line-height: 1.375;
    }
  }

  &__button {

    animation: shake 1s ease-in-out 3;
    transform-origin: center;

    display: flex;
    width: 10rem;
    height: 10rem;
    box-sizing: border-box;
    box-shadow: 0.25rem 0.25rem 0.5rem -2px rgba(0,0,0,.125), 1px 1px 0.25rem 1px rgba(0,0,0,.05), 0.25rem 0.25rem 0.5rem -2px rgba(0,0,0,.05);
    border-radius: 100%;
    background: $color-bg-body;
    font-family: AkkoRoundedPro, sans-serif;

    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    color: $color-text-button;

    position: absolute;
    right: 0;
    top: -2rem;

    &:hover {
      color: darken($color-text-button, 10%);
      transition: color .25s linear, inherit;
      background: darken($color-bg-body, 2.5%);
    }

    & > span {
      position: relative;
      bottom: -.25rem;
    }
  }
}


@keyframes shake {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  25%, 75% {
    transform: translateY(0) scale(1.025);
  }
  50% {
    transform: translateY(0) scale(0.975)
  }
}