.planer-inspirations {

  margin-bottom: 6rem;

  .block__inner {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 2rem;
    overflow: hidden;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;

    }
  }

  &__headline {
    position: absolute;
    z-index: 2;
    width: 55%;
    left: 2rem;
    top: 0;
    background: $color-text-brand;
    color: #FFF;
    font-size: 2.1rem;
    text-transform: uppercase;
    padding: 4rem 2rem;
    font-weight: normal;
    clip-path: polygon(0 0,100% 0,100% calc(100% - 4rem),calc(100% - 4rem) 100%,0 100%);
    line-height: 1.25;
    margin: 0;
    font-family: Klavika, Helvetica, Arial, sans-serif;

    @media (max-width: 900px) {
      width: auto;
      position: static;
      clip: none;

    }

  }

  &__copy {
    padding-left: 2rem;
    padding-top: 15rem;
    font-weight: 500;

    @media (max-width: 900px) {
      padding: 2rem;
    }

    p {
      font-size: 1.25rem;
    }

    a {
      margin-top: 4.125rem;
      font-family: Klavika, Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      display: inline-block;
      font-size: 1rem;
      text-decoration: none;
      color: $color-text-brand;
      font-weight: 500;

      @media (max-width: 900px) {
        margin-top: 2rem;
      }
    }
  }

  &__list {
    align-self: start;
    margin-top: 3rem;
    padding-bottom: 4rem;
    position: static;

    @media (max-width: 900px) {
      margin: 0;
    }

    .splide__pagination {
      transform: scale(1.5);

      &__page {
        border-color: $color-bg-dark;
        background: transparent;
      }
      &__page.is-active {
        border-color: $color-bg-dark;
        background: $color-bg-dark;
      }
    }
  }



  &__slide-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}