.events {

    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    @media (max-width: 1020px) {
        margin-bottom: 1rem;
        
    }

    thead {
        @media (max-width: 1020px) {
            display: none;
            
        }
    }

    th {
        font-family: Klavika, sans-serif;
        color: #738A96;
        text-transform: uppercase;
        font-weight: 400;
        padding: 1rem 1.5rem;
        text-align: left;
    }

}

.event {

    a {
        color: $color-text-brand;
    }

    @media (max-width: 1020px) {
        display: grid;
        grid-template-areas: 'date description' 'date organizer';
        grid-template-columns: 5.5rem 1fr;
        grid-gap: 1rem;
        border-bottom: .25rem solid #FFF;
        padding: 1rem;
        

    }

    @media (max-width: 480px) {
        
        grid-template-areas: 'date' 'description' 'organizer';
        grid-template-columns: 1fr;
    
    }

    &:last-child {
        td {
            border-bottom: 0;
        }
        
    }


    td {
        padding: 1rem 1.5rem;
        border-bottom: .25rem solid #FFF;
        vertical-align: top;

        @media (max-width: 1020px) {
            border: none;
            padding: 0;
            
        }

    }

    &:nth-child(odd) {
        background: #DAE6E6;
    }

    &:nth-child(even) {
        background: #E5EDED;
    }

    &__date {
        color: #DA2028;
        grid-area: date;

        width: 10.5rem;

        @media (max-width: 1020px) {
           width: auto;
            
        }

    }

    &__description {

        color: #2C3C40;
        grid-area: description;

        h3 {
            font-weight: bold;
            margin: 0;
            font-size: 1rem;
        }
        p {
            font-style: italic;
            margin: .5rem 0 0;
            padding: 0;
            font-size: 1rem;
            line-height: 1.25;

            a {
                color: $color-text-brand;
            }
        }
    }

    &__organizer {
        grid-area: organizer;
        width: 12rem; 

        @media (max-width: 720px) {
            width: auto;
             
         }
    }
}