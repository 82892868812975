.zoom-webinar {
    position: relative;
    z-index: 5;

    padding: 0 2rem;
    margin: 0 auto;
    max-width: scale-size(116rem);    

    height: 928px;

    @media (max-width: 520px) {
        padding: 0 1rem;
      }    
  
    &__video {
      
    }
  }

  .zmwebsdk-makeStyles-chatCustomize-3, .zmwebsdk-makeStyles-settingCustomize-4  {
    z-index: 10 !important;
  }