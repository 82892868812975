.subnav {

  padding: 1rem 0;

  &-container {
    margin: 1rem auto 3rem;
  }

  &__inner {
    border-top: 1px solid $color-border-window-gray;
    border-bottom: 1px solid $color-border-window-gray;

    padding: 1rem 1rem .875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 520px) {
      display: block;
    }
  }

  &__items {

    @media (max-width: 520px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
      grid-row-gap: .5rem;
    }


  }

  &__item {
    text-decoration: none;
    color: $color-text-muted;
    display: inline-block;
    padding: 0 1rem;
    text-transform: uppercase;
    font-family: Klavika, sans-serif;
    letter-spacing: 0.04rem;
    line-height: 1.375;

    @media (max-width: 520px) {
      padding: 0;
    }

    &--current {
      color: #DA2028;
    }
  }

  &__cart {
    margin-left: auto;
  }

  &--fixed {
    @media (min-width: 640px) {
      position: fixed; 
      z-index: 3;
      top: 0;
      left: 0;
      right: 0;
      background: $color-bg-body;
    }
  }

  button {
    appearance: none;
    background: none;
    cursor: pointer;
    text-decoration: none;
    color: $color-text-muted;
    display: inline-block;
    padding: 0 1rem;
    text-transform: uppercase;
    font-family: Klavika, sans-serif;
    letter-spacing: 0.04rem;
    line-height: 1.375;
    border: 0;
    font-size: 1rem;

  }
}