.newsroom__articles, .newsroom__aside {
  .typography {
    
    p {
      line-height: 1.375;
    }
  }
}


.newsroom {

  margin-top: 2rem;

  &__nav {
    border-top: 1px solid $color-border-window-gray;
    border-bottom: 1px solid $color-border-window-gray;
    margin: 0rem auto 4rem;
    padding: 1rem 1rem .875rem;

    @media (max-width: 520px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &-item {
      text-decoration: none;
      color: $color-text-muted;
      display: inline-block;
      padding: 0 1rem;
      text-transform: uppercase;
      font-family: Klavika, sans-serif;
      letter-spacing: 0.04rem;
      line-height: 1.375;

      @media (max-width: 520px) {
        padding: 0;
      }

      &--current {
        color: #DA2028;
      }
    }
  }

  &__inner {

    display: grid;
    grid-template-areas: 'articles aside';
    grid-template-columns: 1fr .58fr;
    grid-gap: .5rem;

    @media (max-width: 840px) {
      display: block;
    }

  }

  &__main {

    a {

      border-color: #DA2028;
      grid-template-columns: .75fr 1fr;

      @media (max-width: 920px) {
        border-color: rgba($color-border-window-gray, .33333);
      }
    }

    .newsroom-teaser__preview {
      padding-right: 10rem;
    }

    .newsroom-teaser__headline {
      font-size: 2rem;
    }

  }

  &__articles {
    grid-template: articles;
    padding-bottom: 2rem;
  }

  &__aside {
    padding-bottom: 2rem;
    grid-template: aside;
  }

  &__events {
    padding-bottom: 2rem;
  }

}

.newsroom-teaser {
  border: 1px solid rgba($color-border-window-gray, .33333);
  padding: 1rem;
  margin-top: .5rem;
  text-decoration: none;
  color: $color-text;
  display: block;
  box-sizing: border-box;
  position: relative;



  @media (max-width: 900px) {
    border: 0;
    border-bottom: 1px solid rgba($color-border-window-gray, .33333);
    
    padding: 0 0 2rem;
  
    margin-top: 2rem;


  }

  &--monthly-figure {

    margin-left: 2rem;
    border: 1px solid #9AA9B1;
    background: $color-bg-mystic;
    padding-top: 5rem;

    @media (max-width: 900px) {
      
      padding: 1rem;
      padding-top: 5rem;
      border: 1px solid #9AA9B1;
      margin-bottom: 2rem;

      
    }

    .newsroom-teaser__badge {
      top: -1px;
      padding: 1rem 1rem 1.25rem;
      font-size: 1rem;
    }
  }

  &__figure {

    position: relative;
    left: -3rem;
    background: #2D3D3E;
    padding: 1rem 1rem 2rem 3rem;
    clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);

    .newsroom-teaser {


      &__headline {
        color: #FFF;
        font-size: 6rem;
        margin: 0;
        font-family: Klavika;
        line-height: 1;
      }

      &__tagline {
        color: #FFF;
        margin: 0;
        text-transform: none;
        font-weight: 300;
        font-size: 1.25rem;
      }
    }
  }


  &__more {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: inline-block;
    width: 1rem;
    height: .875rem;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='40' height='40'%3E%3Cpath style='fill:%23DA2028' d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z'%3E%3C/path%3E%3C/svg%3E");


  }

  &__tagline {
    color: #DA2028;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: .04rem;
    margin: 0;
    font-family: Klavika, sans-serif;

    span {
      color: $color-text-muted;
    }

  }



  &__headline {
    font-weight: normal;
    margin: 1rem 0 0;
    font-size: 1.75rem;
    line-height: 1.25;
  }

  &__badge {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.125rem 3rem 1.5rem;
    line-height: 1;
    font-family: Klavika, sans-serif;
    background-size: cover;
    background: url(../../images/bg_badge.svg) no-repeat bottom center;
    background-size: 100% auto;
    color: #FFF;
    text-transform: uppercase;
    font-size: 1.25rem;
    letter-spacing: 0.08rem;
    z-index: 2;

    @media (max-width: 560px) {
      padding-top: 0;
      font-size: 1rem;
      padding: calc(1.125rem * .75) calc(3rem * .75) calc(1.5rem * .75);
    }

  }

  &--top-topic, &--orgatec2022 {
    padding-top: 4rem;

    &.newsroom-teaser {
      border-color: #DA2028;
    }

    @media (max-width: 900px) {
      padding-top: 0;
    }
  }

  &__image {
    picture, video {
      clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);

      @media (max-width: 900px) {
        clip-path: polygon(0 0, 100% 0, 100% 93.5%, 0% 100%);
      }
    }
    img {
      width: 100%;
      display: block;
    }
  }

  &--imageRight, &--imageLeft {

    display: grid;
    grid-template-areas: 'tagline image' 'headline image' 'preview image';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content 1fr;
    grid-column-gap: 1rem;
    min-height: 20.5rem;

    @media (max-width: 920px) {
      grid-template-areas: 'image' 'tagline' 'headline' 'preview' !important;
      grid-template-columns: 1fr !important;
    }    

    .newsroom-teaser {
      &__tagline {
        grid-area: tagline;
        padding-top: 1rem;

        span {
          color: $color-text-muted;
        }

        &--powered-by {
          margin-top: .25rem;
          padding-top: .75rem;
          padding-bottom: .75rem;
          background-size: contain;
          background: url(../../images/powered_by.svg) no-repeat right center;

        }
      }
      &__headline {
        grid-area: headline;
      }

      &__image {
        grid-area: image;
      }

      &__preview {
        grid-area: preview;
        align-self: end;
        padding-bottom: 1rem;
        padding-right: 1rem;

        @media (max-width: 920px) {
          padding-right: 0;
        }

      }
    }
  }

  &--imageLeft {
    grid-template-areas: 'image tagline' 'image headline' 'image preview';

    .newsroom-teaser__preview {
      padding-right: 0;
    }
    
    .newsroom-teaser {
      &--powered-by {
         background-position: left center;
      }
    }
  }


  &--imageTop {

    display: grid;
    grid-template-areas: 'image' 'tagline' 'headline' 'preview';

    .newsroom-teaser {
      &__tagline {
        grid-area: tagline;
        padding-top: 2rem;
      }
      &__headline {
        grid-area: headline;
      }

      &__image {
        grid-area: image;
      }

      &__preview {
        margin-top: 1rem;
        grid-area: preview;
        padding-bottom: 1rem;
      }
    }
  }

  &--listItem {
    display: grid;
    grid-template-rows: max-content max-content 1fr;
  }
}

.newsroom-teaser-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .5rem;

  @media (max-width: 1100px) {
    display: block;
  
  }



  .newsroom-teaser {
    &__preview {
      margin-top: 1rem;
      align-self: end;
      padding-bottom: 1rem;
    }
  }
}

.newsroom-aside {

  margin-top: .5rem;
  border: 1px solid rgba($color-border-window-gray, .33333);
  padding: 1rem;

  &--topic {
    background: #2D3D3E;
    display: block; 
    text-decoration: none;


    p, img {
      margin: 1rem 0 0;
      max-width: 100%;
    }

    
  }

  &--copy-first {

    display: flex;
    flex-direction: column;

    h3 {
      order: 1;
    }
    img {
      order: 3;
    }

    .typography {
      order: 2;
    }
  }

  &__topic {
    margin: 0;
    color: #C6D879;
    font-family: Klavika, sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.04rem;


  }

  &__headline {
    text-transform: uppercase;
    font-family: Klavika, sans-serif;
    margin: 0;
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 1.25;
  }

  &__list {
    list-style: none;
    margin: 1rem 0 0;
    padding: 0;

    li {
      padding: 1rem 0;
      border-bottom: 1px solid #748A96;
      line-height: 1.25;

      &:last-child {
        border-bottom: 0;
      }

    }

    p {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: $color-text;
    }

    b {
      font-weight: normal;
      color: #DA2028;
      display: block;
    }
  }

  &__more {
    font-size: .75rem;
    color: $color-text-muted;
    text-transform: uppercase;
    font-family: Klavika, sans-serif;
    text-align: right;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    b {
      font-weight: normal;
      margin-right: .5rem;
    }

    span {
      position: relative;
      top: -.125rem;
      height: 1.5rem;
      width: 1.5rem;
      background: #FFF;
      box-shadow: 0.25rem 0.25rem 0.5rem -2px rgba(0,0,0,.5);
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: .75rem;
      height: .75rem;
    }
    path {
      fill: $color-text-muted;

    }
  }

  &--newNormal {
    background: $color-bg-gunmetal;
    border: 1px solid $color-bg-gunmetal;

    min-height: 20.5rem;
    box-sizing: border-box;

    img {
      display: block;
      width: 100%;
      padding: 2rem 0 0;
    }
  }

  &--netNews {
    border: 0;
    padding: 0;
    margin: 2rem 0;

    img {
      display: block;
      width: 100%;
      padding: 0;
    }
  }

}

.newsroom-aside--press {
  .newsroom-aside {
    &__article {
      display: grid;
      grid-template-areas: 'text image' 'date image';
      grid-template-columns: 2fr 1.25fr;
      margin-top: 2rem;
      text-decoration: none;
      color: $color-text;
      grid-gap: 1rem;

    }

    &__releases {
      margin: 2rem 0;
    }

    &__date {
      grid-area: date;
      color: #DA2028;
      text-transform: uppercase;
      align-self: end;

      font-size: 1.125rem;
    }

    &__text {
      grid-area: text;

      & > *:first-child {
        margin-top: 0;
      }
    }

    &__image {
      grid-area: image;
      width: 100%;
    }
  }
}