.planer-support {

  &__layout {
    background: linear-gradient(-135deg,transparent 3rem,#f6f5f4 0) left 1px/50% 100% no-repeat,linear-gradient(135deg,transparent 3rem,#f6f5f4 0) right 1px/50.1% 100% no-repeat,linear-gradient(-135deg,transparent 3rem,#575759 0) left top/50% 100% no-repeat,linear-gradient(135deg,transparent 3rem,#575759 0) right top/50.1% 100% no-repeat;
    text-align: center;
    padding: 7rem 0 4rem;

  }

  &__headline {
    font-size: 2.1rem;
    text-transform: uppercase;
    font-family: Klavika, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-align: center;
    margin: 0;
    padding: 0 1rem;
  }

  &__sub-headline {
    font-size: 1.75rem;
    text-align: center;
    margin: 2rem 0 0;
    font-weight: 500;
    padding: 0 1rem;
  }

  &__visual {
    position: relative;
    border-top: 1rem solid $color-bg-dark;
    margin-top: 3rem;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 50%;
      background: $color-bg-dark;
      padding: 1rem;

    }
  }

  &__image {

    position: relative;
    margin: 0 auto;
    border-radius: 50%;
    max-width: 320px;
    aspect-ratio: 1/1;

    border: .375rem solid #f6f5f4;

    img {
      border-radius: 50%;
      aspect-ratio: 1/1;
      width: 100%;
      padding: .5rem;
      border: .25rem solid $color-bg-dark;
    }

  }

  &__contacts {
    list-style: none;
    padding: 0 2rem;
    margin: 2.5rem auto 0;
    display: flex;
    justify-content: center;
    gap: 8rem;

    @media (max-width: 680px) {
      gap: 4rem;
    }

    @media (max-width: 420px) {
      display: block;
    }

    h4 {
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.375;
    }

    a {
      display: flex;
      flex-direction: column-reverse;
      text-decoration: none;
      color: $color-text;

    }

    img {
      width: 100%;
      height: 6rem;
      object-fit: contain;
    }
  }
}