
.topic-intro {
  position: relative;

  &--top-topic {
    .topic-intro-box {
      padding-top: 5rem;
    }
  }

  &__overlay {
    position: absolute;
    max-width: 400px;
    height: auto;
    position: absolute;
    left: 0rem;
    top: -2rem;
  }

  &__link {
    text-decoration: none;
  }

  &__visual {
    img {
      width: 100%;
      display: block;
      aspect-ratio:  1200 / 520;
    }

    @media (max-width: 1125px) {
      position: relative;
      top: -1.5rem;
      z-index: 1;
    }

  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 1125px) {
        flex-direction: column;

        .topic-intro-list {
          order: 1;
          margin: 0;

          &__item:first-child {
            margin-left: 0;
          }
        }

        .topic-intro__nav {
          order: 2;
          margin-top: 1rem;
        }
    }
  }


  &__nav {

      &.button-group {


        @media (max-width: 1125px) {
          margin-top: 3rem;
          display: block;

          .button {
            display: inline-block;
            margin-bottom: 1rem;
          }
        }
      }
   
  }

  &-box {
    position: absolute;
    left: 2rem;
    top: 0rem;
    z-index: 2;
    background: $color-bg-gunmetal;
    color: $color-text-light;
    width: scale-size(42rem);
    box-sizing: border-box;
    padding: 2rem 2rem 4rem;
    padding-left: scale-size(4rem);
    clip-path:  polygon(0 0, 100% 0, 100% 93.5%, 0% 100%);

    @media (max-width: 1125px) {
      position: relative;
      left: 0;
      z-index: 2;
      width: auto;
      //margin: 1rem 0 2rem;
    }

    @media (max-width: 520px) {
      padding: 2rem; 
    }

    &__title {
      font-weight: 300 !important;
      font-size: 1.5rem !important;
      text-transform: uppercase !important;
      margin: 0;
      margin-top: .25rem !important;
    }

    &__topic {
      font-weight: normal !important;
      text-transform: uppercase;
      letter-spacing: 0.025rem;
      line-height: 1.375;
      font-family: Klavika, sans-serif;      
      color: $color-text-muted;
      margin: 0;
      display: block;
    }

    p {
      font-weight: 300;
      letter-spacing: .05rem;
      line-height: 1.375;
      margin-top: 1rem;
      font-family: GestaSemiCondensed-Light, gesta, sans-serif;

      a {
        color: #FFF;
      }
    }
    
  }

  &-list {
    display: flex;
    margin: 0 2rem;
    padding: 0;
    position: relative;
    top: -1.5rem;
    list-style: none;

    @media (max-width: 1125px) {
      position: static;
    }

    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      top: 0;
     
    }

    &__item {
      width: scale-size(15rem);
      margin-left: 2rem;
      transition: transform .25s linear;

      @media (max-width: 800px) {
        margin: 0;
        width: auto;
      }


      &:hover {
        transform: scale(1.1);
      }

      a {
        font-size: .85rem;
        text-decoration: none;
        color: $color-text-muted;
        font-weight: 300;
        text-transform: uppercase;
        font-family: Klavika, sans-serif;
        letter-spacing: .025rem;
      }

      b {
        display: block;
        // font-weight: 600;
        line-height: 1.125;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
        margin-bottom: .5rem;
      }
    }
  }
}

.page--about-us-page {
  .topic-intro-box {
    left: 0rem;
    margin-top: -1.5rem;
    
    &.topic-intro-box--fixed-height {
      min-height: 17.5rem;
    }
  }
}