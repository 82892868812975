.carousel, .examples__list {
  margin-top: 1rem;
  padding-bottom: 3.5rem;

  @media (max-width: 1135px) {
    margin-right: -2rem;
  }

  @media (max-width: 640px) {
    margin-top: 1rem;
  }

  @media (max-width: 560px) {
  
    margin-right: -1rem;
  }

  

  &--dragging {
    a {
      pointer-events: none;
    }
  }

  .splide__track {
    padding-bottom: 1rem;
  }

  .splide__arrow {
    top: 100%;
    transform: translateY(-3rem);

    opacity: 1;
    height: 2rem;
    width: 2rem;
    background: #FFF;
    box-shadow: 0.25rem 0.25rem 0.5rem -2px rgba(0, 0, 0, 0.125), 1px 1px 0.25rem 1px rgba(0, 0, 0, 0.075), 0.25rem 0.25rem 0.125rem -1rem rgba(0, 0, 0, 0.25);

    &--next {
      right: 2rem;
    }

    &--prev {
      left: auto;
      right: 5rem;
    }

    &:disabled {
      opacity: 0.25;
    }

    svg  {
      path {
        fill: $color-text-button;
      }
    }
  }

  .splide__pagination__page {
    font-size: 1.25rem;
  }

  &__navigation {
    display: block;
    text-align: right;
    transform: none;
    width: auto;
    height: auto;
    padding-right: 10rem;
    position: relative;
    bottom: -2rem;
    z-index: 3;
    left: auto;

    .splide__pagination__page {
      appearance: none;
      font-family: Klavika, sans-serif;
      font-size: 1.25rem;
      background: none;
      color: $color-text;
      width: auto;
      height: auto;
      transform: none;
      border: 0;
      margin-left: .5rem;

      &.is-active {
        color: #D4021D;
      }
    }
  }
}

.media-topic__carousel {
  margin-bottom: 4rem;
}

