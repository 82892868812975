#nav-toggle {
  display: block;
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  &:hover {
    span {
      background: $color-text-map;
    }
  }
}

#nav-toggle span{
  display: block;
  position: absolute;
  height: .5rem;
  width: 100%;
  background: #83929B;
  border-radius: .5rem;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;

  animation-name: hamburger-color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}




#nav-toggle span:nth-child(1) {
  top: 0;
}

#nav-toggle span:nth-child(2),#nav-toggle span:nth-child(3) {
  top: 1rem;
}

#nav-toggle span:nth-child(4) {
  top: 2rem;
}

#nav-toggle.open span:nth-child(1) {
  top: 1rem;
  width: 0%;
  left: 50%;
}

#nav-toggle.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-toggle.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-toggle.open span:nth-child(4) {
  top: 1rem;
  width: 0%;
  left: 50%;
}


@keyframes hamburger-color {
  0% {
    background-color:  lighten(#83929B, 10%);
  }
  50% {
    background-color: #a02621;
  }
  100% {
    background-color: lighten(#83929B, 10%);
  }
}