.banner {
  height: 0;
  position: relative;
  padding-bottom:42.5%;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  z-index: 1;

  .block__inner {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
  }
  &-content {
    position: absolute;
    left: 50%;
    bottom: 4rem;
    text-shadow: 0 0 2rem black;
    
    &__title {
      font-weight: 300;
      margin: 0;
      padding: 1.25rem 1rem;
      border-left: .5px solid #FFF;
      text-transform: uppercase;
      font-family: klavika, sans-serif;
      letter-spacing: .04rem;
      font-size: 1.75rem;
    }
    &__text {
      padding-left: 4rem;
    }

    @media (max-width: 1200px) {
      .typography {
        .lead {
          font-size: 1.5rem;;
        }
      }

      .banner-content__title {
        font-size: 1.5rem;
      }
    }

    @media (max-width: 1000px) {
      left: 25%;
    }

    @media (max-width: 890px) {
      display: none;
    }
  }
}