.typography {

  font-family: gesta, sans-serif;

  a {
    color: $color-text-muted;
  }

  b, strong {
    font-weight: bold;
  }

  h1, h2, h3 {
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    line-height: 1.375;
    margin: 1.5rem 0 0;
    font-family: Klavika, sans-serif;

    &:first-child {
      margin-top: 0;
    }

    &.lead {
      font-size: 2rem;
      hyphens: none;
      text-decoration: none;
      letter-spacing: 0;
      text-transform: none;
      margin: .5rem 0 0;

      &.text--uppercase {
        text-transform: uppercase !important;
      }
  
      @media (max-width: 520px) {
        font-size: 1.5rem;
      }
      
    }
  }

  h3 {
    font-size: 1rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .075rem;
    text-transform: none;

    @media (max-width: 520px) {

      font-size: 1.5rem;
    }    

  }

  h3:first-child + h2 {
    font-size: 2rem;
    line-height: 1.375;
    font-family: Klavika, sans-serif;
    margin: 0.25rem 0 0;
    font-weight: normal;

    @media (max-width: 520px) {
      font-size: 1.5rem;
      hyphens: auto;
    }
  }



  p, li {
    margin: .5rem 0 0;
    line-height: 1.5;
    -webkit-text-size-adjust:100%;

    font-size: 1.25rem;
    hyphens: auto;
    -webkit-hyphens: auto;

    @media (max-width: 520px) {
      font-size: 1.125rem; 

    }

    &.lead {
      font-size: 2rem;
      line-height: 1.375;
      font-family: Klavika, sans-serif;
      hyphens: none;

      @media (max-width: 520px) {
        font-size: 1.5rem;
      }
      
    }

 
  }

  ul, ol {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 520px) {
      padding-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.list--bold {
      li::marker {
        font-weight: 500;
      }
    } 
  }

  li {
    margin: .5rem 0 0;
    line-height: 1.5;
  }

  .article-block__text-type + * {
    margin-top: 0;
    
    li:first-child {
      margin-top: 0;
      
      p:first-child {
        margin-top: 0;
      }
    }
  } 
  
  &.article-block__image > a {
    text-decoration: none;
    color: $color-text;
  }

  figcaption {
    margin: .5rem 0 2rem;
    line-height: 1.5;
    font-size: 1rem;
  }

  .lead + p:not(.lead) {
    margin-top: 1rem;
  }

  .text--muted {
    font-size: 1.125rem;
    color: $color-text-muted;
    text-transform: uppercase;
    font-weight: 400;
    font-family: Klavika, sans-serif;
    letter-spacing: .04rem;
  }

  .text--muted-lower {
    color: $color-text-muted;
  }

  .text--uppercase {
    text-transform: uppercase !important;
  }

  .text--question {
    font-style: italic;
    font-weight: 500;
    margin-right: 20%;

    & + .text--answer {
      margin-top: 1rem;
    }
  }

  .text--answer {
    font-style: italic;
    margin-left: 20%;
    font-weight: 300;
    position: relative;

    & + .text--question {
      margin-top: 3rem;
    }


    &::before {
      content: '…';
      position: absolute;
      left: -2rem;
      font-weight: 300;
      font-size: 2rem;
      top: -1.125rem;
    }

    & + .text--answer {
      &::before {
        display: none;
      }
    }
  }

  

  hr {
    border: none;
    height: 1px;
    background: $color-bg-line;
    margin: 4rem 0;

  }

  &--light {
    color: $color-text-light;
  }

  &--center {
    text-align: center;
  }

  &--article {
    p {
      line-height: 1.875;
      @media (max-width: 520px) {

        line-height: 1.5;
      }      
    }

    h3 {
      font-size: 1.5rem;
      margin: 1.5rem 0 0;
    }
  }

  &--small {
    p {
      font-size: 1rem;
      line-height: 1.375;
  
      @media (max-width: 520px) {
        font-size: .875rem; 
      }      
    }

    h3 {
      font-size: 1.5rem;
      margin: 1.5rem 0;   
    }   
  }
}

.article-block--image-before-text  {
  .text--answer {
    margin-left: 0;
  }
}

.no-hyphens {
  hyphens: none !important;
}