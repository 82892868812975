.newsroom-search__calendar {
    display: none;
}

.container {
    margin-top: 1rem;
    display: block;
    box-shadow: none !important;
    border: 1px solid $color-border-window-gray;
    border-radius: 0;

    --color-fg-primary: #D51317;
    --color-bg-inrange: #{rgba(#D51317, .125)};
}

.calendar {

    font-family: Klavika, sans-serif;

    & > .days-grid > .day {
        display: block;
        width: 100%;
        max-width: none;
        text-align: center;
        font-size: 1rem;
    }

    .header {
        display: flex;
        
        .month-name {
            order: 2;
            text-align: center;
        }

        .previous-button {
            order: 1;
        } 

        .next-button {
            order: 3;
        }
    }

}