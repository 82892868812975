body.show-overlay {
  .page {
    pointer-events: none;
    overflow: hidden;

  }

  .media-overlay {
    display: block;
  }
}

.media-overlay {
  background: rgba(#FFF, .75);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 10;



  &__inner {
    max-width: 60rem;
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;
    filter: drop-shadow(.125rem .25rem .25rem rgba(0, 0, 0, 0.25));

    & > * {
      width: 100%;
      background: #FFF;
      padding: 1rem;
      clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);

      @media (max-width: 640px) {
        padding: 0;
      }      

      .typography {
        padding-top: 1rem;
      }
    }
  }

  &--webinar {
    .media-overlay__inner {
      max-width: 86rem;
    }
  }

  &--mini {
    background: none;
    top: auto;
    bottom: 0;
    height: 17rem;

    .media-overlay__inner {
      justify-content: flex-end;
      max-width: scale-size(116rem);
      clip-path: polygon(0 0, 100% 0%, 100% 93.5%, 0% 100%);

      & > * {
        width: 20rem;
      }
    }

    .modal__content {
      padding: 1rem 0;
      margin: 0;
    }

    .modal__title {
      display: none;
    }

    .modal__tagline {
      display: none;
    }

    .modal__header {
      margin: 0;
      padding: 1rem;

      button {
        top: .25rem;
      }

      .modal__minimize {
        &::before {
          content: '\2196';
        }
      }
    }



    .typography {
      display: none;
    }
  }
}