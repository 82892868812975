//@use "../../../node_modules/@zoomus/websdk/dist/css/react-select";

@import "@splidejs/splide/dist/css/splide.min.css";
@import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
@import '@easepick/core/dist/index.css';
@import '@easepick/range-plugin/dist/index.css';
@import '@easepick/preset-plugin/dist/index.css';
@import 'plyr/src/sass/plyr.scss';
@import "autocompleter/autocomplete.css";

@import "forms";


@import "base/fonts";
@import "base/colors";
@import "base/functions";
@import "base/typography";
@import "base/buttons";
@import "base/forms";
@import "base/overlay";
@import "base/animations";
@import "base/images";
@import "base/pagination";
@import "base/calendar";
@import "base/video";
@import "base/carousel";
@import "base/messages";
@import "base/spinner";

@import "vendor/hamburger";
@import "vendor/micromodal/micromodal";
@import "vendor/cookieconsent/consent";
@import "vendor/simple-lightbox";

@import "layout/body";
@import "layout/header";
@import "layout/subnav";
@import "layout/footer";
@import "layout/media-centre";
@import "layout/article";
@import "layout/press-release";
@import "layout/stage";
@import "layout/publications";
@import "layout/about-us";

@import "layout/planer/header";
@import "layout/planer/body";
@import "layout/planer/iframe";
@import "layout/planer/subnav";

@import "layout/studynet/header";
@import "layout/studynet/list";
@import "layout/studynet/blocks";

@import "fields/fields";