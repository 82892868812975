.messages {
  margin: 2rem 0 4rem;
  margin-top: 2rem !important;

  &__message {
    padding: 1rem;
    padding-left: 2rem;
    border-radius: 2rem;
    background: #FFF;
    color: darken(rgb(116 238 28), 50%);
    box-shadow: -2px -2px 6px white, 4px 4px 10px rgba(104, 131, 143, 0.5);
    background: hsl(220.41deg 29.45% 69.67%);
    color: #FFF;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 640px) {
      display: block;
      padding: 1rem;

      .button {
        margin-top: 1rem;
      }
    }

    p {
      line-height: 1.25;
    }

    a {
      color: #FFF;
    }

    &--success {
      background: hsl(128.43deg 36.57% 59.74%);
    }

    .button {
      box-shadow: 4px 4px 10px rgba(104, 131, 143, 0.5);
    }

    & > *:first-child {
      margin-top: 0;
    }
  }
}