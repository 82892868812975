#cart-modal {
  .modal__overlay {
    align-items: flex-start;
    padding-top: 4rem;

    @media (max-width: 640px) {
      padding-top: 0;
    }
  }

  .modal__container {
    flex-grow: 1;
  }

  .button-group {
    margin-top: 4rem;
  }

  .FormBuilder + .button-group {
    margin-top: 2rem;
  }

  .FormBuilder.InputfieldForm {
    margin-top: 0;
  }

  .cart-modal__content:has(.FormBuilder) {
    padding-top: 0;
  }

}


.publication-category {

  padding-top: 3rem;
  padding-bottom: 1rem;

  &:nth-child(odd) {
    background: $color-bg-mystic;
  }

  &__eyebrow {

    display: block;
    margin: 0;
    font-size: 1.125rem;
    color: $color-text-muted;
    text-transform: uppercase;
    font-weight: 400;
    font-family: Klavika, sans-serif;
    letter-spacing: 0.04rem;

  }

  &__headline {
    font-size: 2rem;
    line-height: 1.375;
    font-family: Klavika, sans-serif;
    font-weight: normal;
    margin: 0.5rem 0 0;
    text-transform: uppercase;
  }

  &__intro {
    margin-top: 1rem;
    max-width: 45rem;

    p {
      font-size: 1rem;
      font-family: GestaSemiCondensed, gesta, sans-serif;
      font-weight: normal;
      line-height: 1.25;
    }
  }

  &__carousel {
    margin-top: 2rem;
  }
}

.publication {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &__artwork {
    max-width: 100%;
    aspect-ratio: 1 / 1.414;
    display: flex;
    align-items: flex-end;
    margin-right: 3rem;


    img {
      display: block;
      width: 100%;
      height: auto;
      box-shadow: 0 0 .25rem 0 rgba(0,0,0,.25);

    }
  }

  &__download {
    margin-top: 1.5rem;
  }

  &__headline {
    color: #748A96;
    text-transform: uppercase;
    font-weight: 400;
    font-family: Klavika, sans-serif;
    margin: 1rem 0 0;
    font-size: 1rem;
    text-wrap: balance;
  }

  .typography p, li {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-family: GestaSemiCondensed, gesta, sans-serif;
    font-weight: normal;
    line-height: 1.25;
    text-wrap: balance;
  }

  &__actions {
    margin-top: auto;
  }

  &__meta {
    color: $color-text-muted;
  }

  &__download {
    appearance: none;
    background: none;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    inset: 0;
    cursor: pointer;
    width: 100%;
  }

  &__modal {
    display: none;
  }
}

.publication-modal {

  &__content {
    display: grid;
    grid-template-areas: 'artwork info' 'action actions';
    grid-template-columns: 12rem 1fr;
    grid-gap: 2rem;
    margin-top: 1rem;

    @media (max-width: 640px) {
      grid-template-areas: 'artwork' 'info' 'actions';
      grid-template-columns: 1fr;
    }

  }

  &__artwork {
    display: block;
    max-width: 100%;
    box-shadow: 0 0 .25rem 0 rgba(0,0,0,.25);

    @media (max-width: 640px) {
      max-width: 20rem;
    }

  }

  .typography {
    p:first-child {
      margin-top: 0;
    }
  }

  &__info {

  }

  &__language {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    label {
      margin-right: 1rem;
    }

    select {
      appearance: none;
      box-sizing: border-box;
      height: 40px;
      padding: 0 20px 0 10px;
      background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
      background-repeat: no-repeat;
      background-position: 100% 50%;
    }
  }

  &__actions {
    grid-area: actions;
  }
}

.cart-modal {
  &__items {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  &__item {

    display: grid;
    grid-template-columns: 4rem 1fr 12rem max-content;
    grid-gap: 1rem;
    align-items: start;
    border-top: 1px solid $color-bg-silver;

    @media (max-width: 640px) {
      grid-template-columns: 4rem 1fr 1fr 4rem;
      grid-template-areas: 'artwork title title title' 'action action remove remove';
    }

    &:first-child {
      border-top: 0;
    }

    td {
      padding-top: 1rem;
      padding-bottom: 1rem;

      font-size: 1.125rem;
      line-height: 1.25;

      &:not(.cart-modal__preview) {
        padding-top: 2rem;
      }
    }

  }

  &__preview {

    @media (max-width: 640px) {
      padding-bottom: 0 !important;
    }

    img {
      max-width: 100%;
      aspect-ratio: 1 / 1.414;
      background: #FFF;
      object-fit: contain;
      object-position: top center;
      box-shadow: 0 0 .25rem 0 rgba(0,0,0,.25);
    }


  }

  &__title {
    @media (max-width: 640px) {
      grid-area: title;
      padding-bottom: 0 !important;
    }
  }

  &__print {
    @media (max-width: 640px) {
      padding-top: 0 !important;
      grid-area: action;
      align-self: center;
    }
  }

  &__remove {
    white-space: nowrap;
    text-align: right;

    @media (max-width: 640px) {
      padding-top: 0 !important;
      grid-area: remove;
      align-self: center;

    }
  }

  &__terms {
    display: flex;
    align-items: center;
    gap: 3rem;

    margin: 2rem auto;
    font-size: 1.25rem;

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }

    label {
      position: relative;
      padding-left: 2rem;
      display: block;
      cursor: pointer;
      line-height: 1.25;
    }

    input {
      position: absolute;
      left: 0;
      top: .25rem;
    }

    small {
      display: block;
      color: $color-text-muted;

      a {
        color: $color-text-muted;
      }
    }
  }

  form.FormBuilder {
    clip-path: none;
    padding: 2rem;
  }

}

