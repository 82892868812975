.manual {

  .block__inner {
    display: grid;
    grid-template-columns: 1fr 1.77777fr;
    filter: drop-shadow(0 1rem .5rem rgba(10,10,10,.3));

    @media (max-width: 1120px) {
      display: block;
    }
  }
  
  &__copy {
    background: #ee7f00;
    padding: 2rem 4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__video-container {

    aspect-ratio: 16/9;
  }
}