.block.showroom {
  position: relative;
  max-width: 100rem;
  margin: auto;

  padding-bottom: 6rem;

  @media (max-width: 920px) {
    padding-bottom: 0;
  }

  // display: grid;
  // grid-template-areas: 'showroom showroom' 'space thumbnails';
  // grid-template-columns: 1fr minmax(30rem, 55rem);
}

.showroom {

  &-mobile {
    background: #FFF;
    display: none;  
  
    @media (max-width: 920px) {
      display: block;
    }

    @media (max-width: 520px) {
      margin: 0;
    }

    &__buttons {
      overflow: hidden;
      max-height: 0;
      transition: max-height linear .25s;

      &--open {
        max-height: 100vh;
      }

    }

    &__button {
      appearance: none;
      font-family: Klavika;
      text-transform: uppercase;
      font-size: 1.125rem;
      display: block;
      background: none;
      text-align: center;
      width: 100%;
      box-sizing: border-box;
      border: 0;
      padding: 1rem;
      color: $color-text-button;
      border-bottom: $color-text-button 1px solid;
      cursor: pointer;

    }
  }

  &-box {
    position: absolute;
    left: 0;
    top: 0rem;

    background: $color-bg-gunmetal;
    color: $color-text-light;
    min-width: scale-size(24rem);
    box-sizing: border-box;
    padding: 3rem;
    clip-path: polygon(0 0, 100% 0, 100% 93.5%, 0% 100%);
    z-index: 2;

    @media (max-width: 720px) {
      padding: 1em;
      min-width: 7rem;
    }

    &__title {
      font-weight: 300 !important;
      font-size: 2rem !important;
      text-transform: uppercase !important;
      margin: 0;

      @media (max-width: 720px) {
        font-size: 1.25rem !important;
      }
    }

    &__topic {
      font-weight: 300 !important;
      font-size: 1.125rem !important;
      color: $color-text-muted;
      margin: 0;

      @media (max-width: 720px) {
        font-size: .875rem !important;
      }
    }

    p {
      font-weight: 300;
    }
  }

  &-meta {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    overflow: hidden;
    bottom: 0;
    z-index: 5;
    visibility: hidden;
    transition: top .25s ease-in;
    display: flex;
    
    &--visible {
      visibility: visible;
    }

    &__close {
      appearance: none;
      width: 3rem;
      height: 3rem;
      background: #FFF;
      border: 0;
      position: absolute;
      left: 2rem;
      top: .125rem;
      border-radius: 50%;
      box-shadow: 0 -.25rem .25rem rgba(#000, .025);
      text-align: center;
      cursor: pointer;

      svg {
        width: 1.5rem;
      }

    }

    &__content {
      margin-top: 2.5rem;
      padding: 2rem;
      background: #FFF;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      box-shadow: 0 -.25rem .25rem rgba(#000, .025);

    }
  }
}

.showroom-slider {
  .splide__slide {
    border-top: 0;
    padding-left: 0rem;
    padding-right: 0rem;

    .showroom-slider__slide-img {
      display: block;
      width: 100%;

    }
  }

  &-buttons {
    padding: 2rem 1rem 1rem;
    display: block;
    align-items: flex-start;
    height: 18rem;
    box-sizing: border-box;


    @media (max-width: 520px) {
      height: auto;
    }

    .button {
      width: auto;

      @media (max-width: 520px) {

      }
    }

    &__nav {
      display: flex;
      gap: 1.5rem;
      justify-content: flex-start;

      @media (max-width: 520px) {
        display: none;
      }
    }

    &__logo {
      appearance: none;
      background: none;
      cursor: pointer;
      border: 0;
      width: auto;
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 2rem;
      padding: 0 1.5rem;
      align-items: center;
      display: block;

      @media (max-width: 640px) {
        margin-bottom: 6rem;
      }        

      @media (max-width: 520px) {
        margin-bottom: 1rem;
        padding: 0 4rem;
        width: 100%;
        box-sizing: border-box;;
      }      
  
      img {
        display: block;
        height: 4rem;
        width: 15rem;
        object-fit: contain;
        object-position: left center;
        margin-right: auto;
  
        @media (max-width: 640px) {
            width: 10rem;
        }

        @media (max-width: 520px) {
          width: 100%;
          margin: 0 auto;
          object-position: center center;
        }

      }
    }
  }



  &__slide {
    position: relative;
  }

  &__meta {
    background: #FFF;
    padding: 2rem;
    position: absolute;
    height: 0;
    left: 0;
    right: 0;

    h2 {
      font-weight: normal;
      margin: 0 0 2rem;
    }
  }

  &__address {
    font-style: normal;
    line-height: 1.25;

  }

  &__info {
    display: flex;
    align-items: flex-start;

    & > * {
      margin-right: 2rem;
    }
  }

  &__links {
    .button {
      margin-right: 1rem;
    }
  }

  &__contact {
    
    margin: 0;
    margin-right: 2rem;

    li {
      display: flex;
      justify-content: space-between;
      margin-top: .75em;
      &:first-child {
        margin-top: 0;
      }

      h3 {
        font-size: 1rem;
        font-weight: normal;
        text-transform: uppercase;
        color: $color-text-muted;
        margin: 0;
        margin-right: 1rem;
      }
    }

    a {
      color: $color-text-map;
    }
  }

}

.is-active {

  .showroom-info {

    &__button {
      position: absolute;
      appearance: none;
      background: none;
      height: 2rem;
      width: 2rem;
      cursor: pointer;
      border-radius: 50%;
      border: 0;
      align-items: center;
      justify-content: center;
      display: flex; 
      opacity: .9;
      transition: opacity .5s;
      z-index: 2;
      &:hover {
        opacity: 1;
      }

      @media (max-width: 920px) {
        display: none;
      }

      &--mobile {

        left: 50%;
        transform: translateX(-1rem) translateY(-4rem);

        @media (max-width: 920px) {
          display: flex;
        }
      }

      span {
        position: absolute;
        animation: ripple 5s infinite;
        border-radius: 100%;
        border: none;
        background-color: transparent;
        box-shadow: inset 10px 10px 20px rgba(#000, .5), inset -10px -10px 20px rgba(#ffffff, .75);
        transition: 0.75s ease-in all;
        opacity: 0;
        background-blend-mode: multiply;
        border: 1px solid #FFF;

        
        &:nth-child(1) {
            animation-delay: 0ms;
        }
        
        &:nth-child(2) {
            animation-delay: 500ms;
        }

        &:nth-child(3) {
          animation-delay: 2500ms;
          
        }

        &:nth-child(4) {
          animation-delay: 3000ms;
        }

        &:nth-child(5) {
          background: darken(#FFF, 1%);
          box-shadow: none;
          z-index: 2;
          animation: none;
          opacity: 1;
          width: 2rem;
          height: 2rem;
          box-shadow: 0 .25rem .25rem rgba(#000,.25), 0 -.25rem .25rem rgba(#FFF,.25);
        }
      }

    }

  }
}

.splide__slide:hover {

  .showroom-info__button {

    &::before {
      animation-play-state: running;
    }

  }
}

.modal-slider {

  max-width: 60rem;

  .splide__slide {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .splide__track {
    padding-bottom: 1rem;
  }

  .splide__arrow {
    top: 100%;
    transform: translateY(-3rem);

    opacity: 1;
    height: 2rem;
    width: 2rem;
    background: #FFF;
    box-shadow: 0.25rem 0.25rem 0.5rem -2px rgba(0, 0, 0, 0.125), 1px 1px 0.25rem 1px rgba(0, 0, 0, 0.075), 0.25rem 0.25rem 0.125rem -1rem rgba(0, 0, 0, 0.25);

    &--next {
      right: 0;
    }

    &--prev {
      left: auto;
      right: 4rem;
    }

    &:disabled {
      opacity: 0;
    }

    svg {
      path {
        fill: #AABECA;
      }
    }
  }

  .splide-text {
    display: flex;
    justify-content: space-between;
    height: 3rem;
    padding-top: 1.25rem;
    padding-right: 8rem;
    box-sizing: border-box;
    color: #748A96;

    &__description {
      font-style: italic;
      line-height: 1.25;
      max-width: 75%;
    }

    &__pagination {
      text-align: right;
    }
  }

}

.showroom-thumbnails  {
  overflow: hidden;
  position: relative;
  padding: 0;
  margin: -21rem 0 4rem;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  font-family: Klavika, sans-serif;

  @media (max-width: 960px) {
    margin-top: -17.5rem;
  }

  @media (max-width: 520px) {
    margin: 0 0 1rem;
  }

  &__headline {
    display: none;
    font-family: Klavika;
    font-size: .875rem;
    font-weight: 300;
    text-transform: uppercase;
    margin: 1rem;
    letter-spacing: .025rem;

    @media (max-width: 520px) {
      display: block;
      margin-top: 2rem;
      margin-bottom: .5rem;
    }
  }

  &__splide {
    width: 40rem;
    pointer-events: all;
    position: relative;
    padding-bottom: 4rem;

    @media (max-width: 1380px) {
      width: 30rem;
    }

    @media (max-width: 960px) {
      width: 20rem;
    }

    @media (max-width: 520px) {
      width: 100%;
    }
  }

  .splide__slide {
    padding: .5rem;
    cursor: pointer;
    text-decoration: none;
    color: $color-text-muted;
    font-weight: 500;
    text-transform: uppercase;
    filter: saturate(-50);
    transition: filter .25s linear, transform .25s linear;

    @media (max-width: 920px) {
      font-size: .875rem;
    }


    img {
      width: 100%;
      height: auto;
      display: block;
      clip-path:  polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
      margin-bottom: .5rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }



  &__current {
    filter: grayscale(0) !important;
    opacity: 1 !important;

    span {
      color: $color-text-map;
    }
  }

  .splide__arrow {

    opacity: 1;
    height: 2rem;
    width: 2rem;
    background: #FFF;
    box-shadow: 0.25rem 0.25rem 0.5rem -2px rgba(0, 0, 0, 0.125), 1px 1px 0.25rem 1px rgba(0, 0, 0, 0.075), 0.25rem 0.25rem 0.125rem -1rem rgba(0, 0, 0, 0.25);
    top: auto;
    bottom: .25rem;

    &--prev {
      left: auto;
      right: 6rem;
    }

    &--next {
      right: 3rem;
    }

    &:disabled {
      opacity: .25;

      &:hover {
        opacity: .25;
      }
    }

    &:hover {
      opacity: 1;
    }

    svg {
      path {
        fill: $color-text-button;
      }
    }
  }

}

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: transparent;

}


.splide__pagination__page {
  background: #82939C;
  border: 1px solid #82939C;

  &.is-active {
    transform: scale(1);
    background: #ffffff;
  }
}

.modal {

  &__content-top {
    margin: .5rem 0 4rem;
  }

  &__logos {
    display: flex;

    img {
      margin-right: 1.5rem;
      height: 4.5rem;
      width: auto;
    }
  }
}

@keyframes ripple {
  0% {
     width: 2rem;
     height: 2rem;
     opacity: 1;
  }
  100% {
     width: 7.5rem;
     height: 7.5rem;
     opacity: 0;
  }
}