.block.about-us {
  margin-top: 2rem;

  .block__inner {
    .about-us__inner {
      border: 1px solid $color-border-block-red;
      padding: 3rem 6rem;

        @media (max-width: 720px) {
            padding: 2rem;
        }
    }
  }

  h3 {
    color: $color-text-headline;
  }

  h2 + p {
    margin-top: 1rem;
  }
}

.about-us {

  &--image-before-text, &--image-after-text, &--image-left, &--image-right, &--gallery-top {
    .about-us__inner {
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr;

    }
    .about-us__copy {
        grid-area: copy;
    }
    .about-us__image {
        grid-area: image;
    }
    .about-us__headline {
      grid-area: headline;
    }
    .about-us__gallery {
        grid-area: gallery;
    }
    .about-us__video {
        grid-area: video;
    }
  }

  &--image-before-text {
    .about-us__inner {
      grid-template-areas: 'headline' 'image' 'copy' 'video';
    }
  }
  &--image-after-text {
    .about-us__inner {
      grid-template-areas: 'headline' 'copy' 'image' 'video';
    }
  }
  &--image-left {
    .about-us__inner {
      grid-template-areas: 'headline headline' 'image copy' 'video video';
      grid-template-columns: max-content 1fr;

      @media (max-width: 960px) {
        grid-template-areas: 'headline' 'copy' 'image' 'video';
        grid-template-columns: 1fr;
      }

      .about-us__image img {
        margin-left: auto;
        margin-right: 0;
        max-width: 11.25rem;

        &[src*=svg] {
          width: 11.25rem;
        }
      }
    }
  }
  &--image-right {
    .about-us__inner {
      grid-template-columns: 1fr max-content;
      grid-template-areas: 'headline headline' 'copy image' 'video video';

      .about-us__image img {
        margin-left: auto;
        margin-right: 0;
        max-width: 11.25rem;

        &[src*=svg] {
          width: 11.25rem;
        }
      }

      @media (max-width: 960px) {
        grid-template-areas: 'headline' 'copy' 'image' 'video';
        grid-template-columns: 1fr;
      }
    }
  }
  &--gallery-top {
      .about-us__inner {
        grid-template-areas: 'headline' 'gallery' 'copy' 'video';
      }
  }

  &__gallery {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    figure {
      margin: 0;
      img {
        width: 100%;
        display: block;
        clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
      }
      figcaption {
        line-height: 1.25;
        font-size: 1rem;
        margin-top: .75rem;
      }
    }
  }

  &__copy {

    .typography {
      max-width: 48rem;
    }

    &--columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

        @media (max-width: 960px) {
          display: block;
          margin-top: 2rem;
        }
    }
  }

  &__image {

    img {
      width: 100%;
      max-width: 48rem;
    }

    .typography {
      max-width: 48rem;
      margin-top: 2rem;

      p {
        font-size: 1.125rem;
      }

    }
  }

  &__video {
    max-width: 48rem;
  }
}

