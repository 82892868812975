.planer-experts {

  &__layout {
    background: linear-gradient(-135deg,transparent 3rem,#f6f5f4 0) left 1px/50% 100% no-repeat,linear-gradient(135deg,transparent 3rem,#f6f5f4 0) right 1px/50.1% 100% no-repeat,linear-gradient(-135deg,transparent 3rem,#575759 0) left top/50% 100% no-repeat,linear-gradient(135deg,transparent 3rem,#575759 0) right top/50.1% 100% no-repeat;
    padding: 0 1rem 4rem;
    border-bottom: 1px solid #575759;

  }

  &__copy {
    text-align: center;
    padding: 6rem 2rem 3rem;

    h2 {
      color: $color-text-brand;
    }
  }

  &__list {
    background: #FFF;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  &__logo {
    width: 100%;
    height: 8rem;
    object-fit: contain;
  }
}