html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: gesta, sans-serif;
  line-height: 1;

  &.loading main {
    opacity: .5;
    filter: blur(5px);
  }
}

main {
  opacity: 1;
  transition: opacity linear .25s, filter linear .25s;
}

.page {
  margin: 0 auto;
  background: $color-bg-body;
  position: relative;

  &--home {
    background-blend-mode: multiply;
    background: $color-bg-body url(../../images/bg_waves.webp) no-repeat;
    background-position: 80% 900px;
    background-size: 1500px;
  }
}

.block {
  margin-top: 4rem;
  position: relative;
  z-index: 2;
  &:first-child {
    margin-top: 0;
  }

  &:last-child:not(.banner) {
    @media (max-width: 1145px) {
      padding-bottom: 3rem;
    }
  }
}

.block__inner, .header__inner, .footer__inner, .header-breadcrumbs__inner, .subnav__inner, .header-planer__inner, .header-studynet__inner, .publication-category__inner {
  padding: 0 2rem;
  position: relative;
  margin: 0 auto;
  max-width: scale-size(116rem);
  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 520px) {
    padding: 0 1rem;
  }
}

.planer__inner {
  padding: 0 8rem;
  position: relative;
  margin: 0 auto;
  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 116rem) {
    padding: 0 2rem;
  }

  @media (max-width: 800px) {
    padding: 0;
  }
}
