
.member-search__controls {

  margin-top: 2rem;

  .FormBuilder.InputfieldForm {
    padding: 0;
    background: none;
    margin: 0;
    clip-path: none;
  }

  .Inputfields {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 860px) {
      display: flex;
      flex-direction: column;
    }

  }

  .InputfieldText, .InputfieldSelect {
    display: block !important;
    margin-top: 0 !important;

    select {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .Inputfield_showAll {
    grid-column: span 3;
    text-align: center;

    @media (max-width: 1080px) {
      grid-column: auto;
      text-align: right;
    }

    @media (max-width: 860px) {
      text-align: center;
    }
  }

  select {
    width: 100%;
  }
}

.member-search-results {

  margin-top: 3.5rem;

  &__empty {
    text-align: center;
  }
}

.member-search-result {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: 7rem 1fr;
  grid-template-areas: "logo text";
  gap: 4rem;
  padding-top: 1.25rem;
  border-top: 1px solid $color-bg-line;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column-reverse;
  }

  &__text {
    grid-area: text;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 1.125rem;
    line-height: 1.25;
    gap: 1rem;

    @media (max-width: 860px) {
      grid-template-columns: 1fr;
    }

  }

  &__address {

    h4 {
      font-size: 1.375rem;
      display: block;
      margin: 0 0 .25rem;
      line-height: 1.125;
      text-wrap: balance;
      font-weight: normal;
      color: $color-text-muted;
    }

  }

  &__phone {
    font-size: 1.125rem;
  }

  &__link {
    font-size: 1.125rem;
    color: $color-text-muted;
    text-decoration: underline;
    
    
  }

  &__contact {
    text-align: right;
    align-self: end;
    display: flex;
    gap: .5rem;
    justify-content: end;
    align-items: center;

    @media (max-width: 500px) {
      justify-content: flex-start;
    }

  }

  &__logo {
    grid-area: logo;

    img {
      width: 100%;
      aspect-ratio: 3/2;
      object-fit: contain;

      @media (max-width: 860px) {
        max-width: 8rem;
        margin: 0 auto;
        display: block;
        gap: 2rem;
      }


    }

  }
}