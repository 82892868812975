.preview-list {

  &--darker {
    background: $color-bg-lightblue;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    display: grid;

    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;

    &--grid-2 {
      grid-template-columns: repeat(2, 1fr);

      .preview-list__image {
        clip-path: polygon(0 0, 100% 7%, 100% 90%, 0% 100%) !important;
        aspect-ratio: 1280 / 880 !important;
      }
    }

    &--rows {
      display: block;
      
      .preview-list__item {
        margin-top: 2rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }



  &__caption {
    color: $color-text-muted;
    text-transform: uppercase;
    font-weight: 400;
    font-family: Klavika, sans-serif;
    letter-spacing: .04rem;
    font-size: 1.125rem;
  }

  &__item {
    transition: transform .25s linear;
    position: relative;

    &:hover {
      //transform: scale(1.025);
    }

    a {
      text-decoration: none;
      color: $color-text;

    }

    .preview-list__image {
      width: 100%;
      display: block;
      clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
      margin-bottom: 1rem;
      transition: clip-path linear .25s;
      background-size: cover;
      aspect-ratio: 640 / 880;
    }

    &:nth-child(2) {
      .preview-list__overlay {
        right: auto;
        left: 5%;
        bottom: 25%;
      }
    }

    &:nth-child(3) {
      .preview-list__overlay {
        right: 5%;
        left: 5%;
        bottom: 0;
        top: 20%;

        h3 {
          text-align: right;
        }
        p {
          font-size: .75rem;
        }
      }
    }

    &:nth-child(4) {
      .preview-list__overlay {
        bottom: auto;
        top: 20%;
        right: 10%;
      }
    }

  }

  &__preview {
    h3 {
      color: #748A96;
      text-transform: uppercase;
      font-weight: 400;
      font-family: Klavika, sans-serif;
      line-height: 1.25;
    }
    p {
      margin-top: .5rem;
      font-size: 1rem;
      font-family: GestaSemiCondensed, gesta, sans-serif;
      font-weight: normal;
      line-height: 1.25;
    }
  }

  &__overlay {
    position: absolute;
    right: 20%;
    bottom: 40%;

    text-shadow: 0 0 1rem rgba(0,0,0,1);

    h3 {
      font-size: 1.125rem;
      color: #FFF;
      text-transform: uppercase;
      font-weight: 600;
      font-family: Klavika, sans-serif;
      letter-spacing: .04rem;
    }
  }


  &--showroom {
    background: $color-bg-lightblue;
    position: relative;
    z-index: 2;
    padding: 14rem 0 7rem;
    margin-top: 12rem;

    .topic-intro-box {
      top: -20rem;
      width: 30rem;
      padding-bottom: 3rem;
    }

    .preview-list {
      &__list {
        flex-wrap: wrap;
        margin: auto -.5rem;
      }

      &__showrooms {
        position: absolute;
        width: 40rem;
        left: 24.9rem;
        top: -20.25rem;
        z-index: 3;
      }

      &__filter {
        padding: 2rem 0;



        input {
          width: 6rem;
        }
      }

      &__item {
        margin: 0;
        border: .5rem transparent solid;
        box-sizing: border-box;
        margin-bottom: 1rem;
      }

      &__image {
        clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
        height: 0;
        padding-bottom: 83%;
        margin-bottom: 1rem;
      }

    }

  }

  &--smaller {
    .preview-list {
      &__image {
        clip-path: polygon(0 0, 100% 10%, 100% 85%, 0% 100%);
        margin-bottom: 1rem;
      }
    }
  }
}