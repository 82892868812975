.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  filter: drop-shadow(.125rem .25rem .125rem rgba(0, 0, 0, 0.1));
}

.modal__form {


  .FormBuilderViewport {
    
    min-width: 50rem;

    @media (max-width: 60rem) {
      min-width: 30rem;
    }

    @media (max-width: 40rem) {
      min-width: 0;
    }
  }
}


.modal__container {
  background-color: #fff;
  padding-bottom: 2rem;
  max-width: 60rem;
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  clip-path: polygon(0 0, 100% 1.75%, 100% 95.5%, 0% 100%);

  @media (max-width: 960px) {
    clip-path: none;
  }

}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  padding: 2rem 0;
  padding-bottom: 0;
}

.modal__footer {
  padding: 0 2rem 0;

}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

.modal {


  &__header {
    border-bottom: #2D3D3E 1px solid;
    padding: 3rem 0 1rem;
    display: block;
    position: relative;
    @media (max-width: 640px) {
      margin: 0 1rem;
    }    
  }

  &__tagline {
    font-family: Klavika, sans-serif;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 300;
    color: $color-text-muted;
    margin: 0 0 .25rem;
  }

  &__title {
    font-family: Klavika, sans-serif;
    font-size: 2.5rem;
    font-weight: normal;
  }

  &__close, &__minimize  {
    position: absolute;
    right: 0;
    top: 2.5rem;
    cursor: pointer;
    color: #2D3D3E;
    border: 1px solid #2D3D3E;
    border-radius: .25rem;
    width: 1.25rem;
    height: 1.25rem;
    text-align: center;
    padding: 0;
    background: transparent;
    &:before {
      content: "\2715";
    }
  }

  &__content {
    margin-top: 0;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0,0,0,.8);
    max-height: 80vh;
    overflow: auto;
    padding: 2rem;
    padding-top: 1rem;

    @media (max-width: 640px) {
      padding: 1rem;
    }
  }

  &__info {

    padding: 0 2rem 4rem;
    margin-top: -4rem;
    
    @media (max-width: 640px) {
      padding: 0 1rem 4rem;
    }
  }

  &__minimize {
    right: 1.5rem;
    &:before {
      content: "–";
    }
  }


  &__description {
    border-top: #2D3D3E 1px solid;
    padding-top: 1rem;
    margin-top: 1rem;
  }

}