.header {
  &-studynet {
    background: #2e3d3e;

    &__inner {
      padding: 2rem;

      a {
        display: block;
        overflow: hidden;
      }
    }

    &__logo {
      width: 36rem;
      @media (max-width: 680px) {
        width: 30rem;
      }      
    }

    &__logo-claim {
      @media (max-width: 680px) {
        display: none;
      }
    }

    &__visual-img {
      width: 100%;
    }
  }
}

.article-header--studynet {
  grid-gap: 0;
  grid-row-gap: 0;
  grid-template-areas:
    "space tagline social space2"
    "space headline headline space2";
  grid-template-columns: calc(15% + 3rem) 1fr .5fr calc(15% + 3rem);
  
  @media (max-width: 1190px) {
    grid-template-columns: 2rem 1fr .5fr 2rem;
  }

  @media (max-width: 520px) {
    grid-template-columns: 1rem 1fr .5fr 1rem;
  }

  @media (max-width: 640px) {
    grid-template-areas:
    "space social social space2"
    "space tagline tagline space2"
    "space headline headline space2";
  }

  & > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .article-header {
    
    &__headline {
      grid-area: headline;
      margin-top: 3rem;
      font-size: 2.5rem;

      @media (max-width: 640px) {
        hyphens: auto;
        margin-top: 1rem;
      }

    }

    &__tagline {
      grid-area: tagline;
      margin-top: 0;
      align-self: end;
      padding-bottom: 1rem;
      border-bottom: solid 1px $color-border-window-gray;

      @media (max-width: 640px) { 
        border: 0;
        padding-top: 1rem;
      }

    }

    &__social {
      grid-area: social;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      padding-bottom: 1rem;
      border-bottom: solid 1px $color-border-window-gray;

      @media (max-width: 640px) { 
        justify-content: flex-start;
      }
    }
  }

}