.block.examples {

  .block__inner {
    & > hr {
      margin: 5rem auto 3rem;
      border: none;
      background: #575759;
      height: 1px;
    }
  }

  .examples {
    &__copy {
      background-color: #101919;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 2rem 2rem 15%;
    }

    &__list {
      margin-top: -7.5rem;

      @media (max-width: 900px) {
        margin-top: 0;
      }

      .splide__slide {
        padding: 2rem 0;
      }
    }
  }
}

.plan {
  filter: drop-shadow(0 1rem .5rem rgba(10,10,10,.3));
  position: relative;

  &__inner {
    background: #FFF;
    clip-path: polygon(0 0,100% 0,100% calc(100% - 4rem),calc(100% - 4rem) 100%,0 100%);
    padding-bottom: 2rem;
  }

  &__visual {
    img {
      width: 100%;
      display: block;
    }

  }

  &__title {
    padding: 0 2rem;
    margin: 2rem 0 0;
    font-size: 2.1rem;
  }

  &__copy {
    margin: 1rem 0 0;
    padding: 0 2rem;
  }

  &__button {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}