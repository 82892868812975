@import './../../../site/modules/FormBuilder/frameworks/basic/main.css';
@import './base/fonts';
@import './base/colors';
@import './base/buttons';


body.InputfieldColumnWidthsInit {
    margin: 0 !important;

    #content {
        padding: 0 !important;
    }

}


.FormBuilder, .FormBuilder input, .FormBuilder textarea, .FormBuilder select, .FormBuilder button, .FormBuilder .ui-widget {
    font-family: Gesta, sans-serif;
    font-size: 1rem;
    line-height: 1.25em;
}

.FormBuilder .InputfieldGrid {
    display: grid;
    grid-template-columns: 12rem 1fr 1fr;
    align-items: start;
    grid-gap: 1.5rem;
    margin-top: 1.5em;

    @media (max-width: 640px) {
        display: block;

    }

    h3 {
        font-weight: normal;
        font-size: 1.125rem;
        text-transform: uppercase;
        color: #647883;
        font-family: Klavika, sans-serif;
        align-self: center;
        margin: 0;
    }

    &--headline {
        grid-template-columns: 12rem 1fr;
        margin-top: 3rem;

        h3 {
            grid-column-start: 2;

        }
    }

    &--salutation-and-title {
        grid-template-columns: 12rem min-content 12rem;
    }

    &--single {
        grid-template-columns: 12rem 1fr;
    }

    &--city-postcode {
        grid-template-columns: 12rem 7.5rem 1fr;
    }

    &--no-margin {
        margin-top: 0;
    }

    &--submit {
        margin-top: 2.75rem;
    }



    .InputfieldLabel{
        font-weight: normal;
        font-size: 1.125rem;
        text-transform: uppercase;
        color: #647883;
        font-family: Klavika, sans-serif;
        align-self: flex-start;
        padding-top: .75rem;

        @media (max-width: 640px) {
            margin-bottom: .75rem;
            display: block;
    
        }

        small {
            display: block;
            text-transform: none;
            font-family: gesta, sans-serif;
            line-height: 1.375;
            margin-top: .5rem;
        }

    }

    .Inputfield {
        margin-top: 0 !important;

        @media (max-width: 640px) {
            margin-top: .75rem !important;
        }
    }

    .InputfieldCheckbox, .InputfieldSubmit {
        margin-left: 0 !important;
    }

}



.FormBuilder {


    .alert {
        color: $color-text;
        font-size: 1.125rem;
        padding: 1rem;
    }

    .form-errors {
        margin-bottom: 3rem;;
    }

    .error {
        color: red;
        font-size: 1.125rem;     

        &:first-child {
            margin-top: 0;

        }
    }

    .alert-success {
        background: rgba(120, 188, 97, .25);

    }

    .alert-error {
        background: rgba($color-text-brand, .25);
    }

    a {
        color: $color-text-brand;
    }
}

.FormBuilderViewport {
    margin: 2rem 0 3rem;
}

.FormBuilder.InputfieldForm {

    background: #DFEAEA;
    padding: 4rem 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 93.5%, 0% 100%);
    
    &.InputfieldForm--no-clip {
        clip-path: none;
        padding: 1rem 2rem 4rem;
    }

    @media (max-width: 640px) {
        padding: 2rem 1rem;
    }

    .Inputfield {
        margin: 0;
        margin-top: 1.5em;

        &:first-child {
            margin-top: 0;
        }
    }

    .Inputfield {

        .InputfieldHeader {
            font-weight: normal;
            font-size: 1.125rem;
            padding-bottom: .5rem;
            text-transform: uppercase;
            color: #647883;
        }
    }

    .InputfieldContent:has([required]):not(:has(select)) {
        position: relative;
        &::after {
            content: '*';
            color: #647883;
            position: absolute;
            right: .5rem;
            top: 0;
            bottom: 0;
            font-size: 2rem;
            display: flex;
            align-items: center;
            pointer-events: none;

        }

        &:has([type="checkbox"]) {
            &::after {
                display: none;
            }
        }

        &.form__inline-error {
            &::after {
                color: $color-text-brand;
            }
        }

    }

    .InputfieldText, .InputfieldEmail, .InputfieldSelect, .InputfieldTextarea, .InputfieldButton {
        display: flex;
        gap: 2rem;

        @media (max-width: 640px) {
            display: block;
            
        }

        .InputfieldHeader {
            width: 12rem;
            flex-grow: 0;
            flex-shrink: 0;
            margin-top: .7125rem;
            font-family: Klavika, sans-serif;

            @media (max-width: 640px) {
                width: auto;
                
            }
        }

        .InputfieldContent {
            flex-grow: 1;
        }

    }

    .InputfieldButton .InputfieldContent {
        align-self: end;
        padding-bottom: .25rem;
    }

    .InputfieldCheckbox, .InputfieldSubmit {
        margin-left: 12rem;

        @media (max-width: 640px) {
            margin-left: 0;  
        }
    }

    .InputfieldCheckbox {

        & + .InputfieldCheckbox {
            margin-top: .5rem;
        }

        label {
            color: $color-text;
            font-size: 1.125rem;
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            line-height: 1.25;

        }

        input {
            width: 1rem;
            height: 1rem;
            border-width: 1px;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 0;
            accent-color: #748A96;
        }

    }

    select:not([multiple]):not([size]) {
        font-size: 1.125rem;
        border: 1px solid #748A96;
        width: auto;
        padding-right: 2rem;
    }


    input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not([type="range"]), .FormBuilder.InputfieldForm select:not([multiple]), textarea {
        font-size: 1.125rem;
        border: 1px solid #748A96;

        &:focus {
            border-color: lighten(saturate(rgba(#748A96, .9), 10%), 20%);
            outline: 2px solid lighten(saturate(rgba(#748A96, .9), 10%), 20%);
        }
    }


    .form__inline-error {
        select {
            border-color: $color-text-brand !important;
            background-color: lighten($color-text-brand, 50%) !important;
        }
        textarea, input[type="text"], input[type="email"], input[type="tel"], input[type="number"] {
            border-color: $color-text-brand !important;
            background-color: lighten($color-text-brand, 50%) !important;
        }
    }

    textarea {
        padding-top: .5rem;
        padding-bottom: .5rem;
        width: 100%;
    }

    button:not(.button) {
        font-weight: normal !important;
        @extend .button;

        background: #ED1C24;
        color: #FFF;

        &:hover {
            background: darken(#ED1C24, 10%);
            color: #FFF;
        }
    }

    .input-error {
        margin-top: .5rem;
    }

    .InputfieldMarkup {
        margin-left: 12rem;

        @media (max-width: 640px) {
            margin-left: 0;  
        }

        .InputfieldHeader {
            display: none;
        }
        h2 {
            color: #d51317;
            font-family: Klavika, sans-serif;
            text-transform: uppercase;
            font-weight: normal;
            margin: 0 0 1rem;
        }
    }

}

.FormBuilder {
    .InputfieldGroup {

        margin-top: 1rem;
        display: flex;

        &:first-child {
            margin-top: 0;
        }
        
        .Inputfield {
            margin-top: 0;
            float: none;
        }

        select:not([multiple]):not([size]) {
            width: 100%;
        }
    } 
}

#Inputfield_country {
    width: 100%;
}

#FormBuilderSubmitted {
    padding: 4rem 2rem;
    text-align: center;

}

.form {
    &__checkboxes {
        font-size: 1.125rem;

        label {
            margin-top: .5rem;
            display: block;
            cursor: pointer;

            &:has(.required) {

                &::after {
                    content: '*';
                }
            }

            &.form__inline-error {
                color: $color-text-brand;
            }

        }
    }
}

