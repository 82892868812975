picture, video {
  max-width: 100%;
  display: block;
  img {
    max-width: 100%;
    display: block;
  }
}

#ZOOM_WEB_SDK_SELF_VIDEO {
  max-width: none;
}