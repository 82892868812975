.MarkupPagerNav {
    margin: 1rem 0 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    position: relative;

    a {
        display: block;
        padding: .125rem .25rem;
        font-size: 1rem;
        color: $color-text-muted;
        text-decoration: none;
    }

    .MarkupPagerNavOn {
        a {
            color: #D4021D;
        }
    }

}