.media-topics {

}

.media-search {

  margin-left: auto;
  margin-right: 1rem;
  position: relative;

  @media (max-width: 520px) {
    margin-right: 0;
    margin-top: 1rem;
  }

  &__headline {
    font-size: 1rem;
    color: $color-border-form;
    font-weight: normal;
    font-family: AkkoRoundedPro, sans-serif;
    margin: 0;
  }

  &__input {
    padding: .25rem 1rem;
    font-family: gesta, sans-serif;
    font-size: 1rem;
    display: block;
    box-sizing: border-box;

    @media (max-width: 520px) {
      width: 100%;
    }

    &--filled + .media-search__clear {
        opacity: 1;
    }
  }

  &__clear {
    appearance: none;
    color: $color-text-muted;
    font-size: 1rem;
    line-height: 1rem;
    position: absolute;
    right: 0rem;
    padding-right: .5rem;
    top: 0rem;
    bottom: 0;
    line-height: 100%;
    appearance: none;
    background: none;
    border: none;
    opacity: 0;
    z-index: 2;
  }
}

.media-search-results {


  display: none;
  background: $color-bg-mystic;

  padding: 1rem 0 4rem;
  margin-top: 4rem;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    grid-gap: 1.5rem;

  }
}

.media-topic {
  background: $color-bg-mystic;
  overflow: hidden;
  position: relative;
  isolation: isolate;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40%;
    background: $color-bg-body;
    z-index: 1;
  }

  @media (max-width: 640px) {
    --offset: 0 !important;
  }

  &__inner {
    @extend .block__inner;
    padding-top: 3rem;
    position: relative;
    z-index: 2;

    @media (max-width: 640px) {
      padding-top: 1rem;
    }

  }

  &__bg {
    position: absolute;
    top: 0;
    overflow: hidden;
    right: 2rem;

    pointer-events: none;
    z-index: -1;

    img {
      position: relative;
      top: -2px;
    }

    @media (max-width: 640px) {
      
      position: static;
      padding-bottom: 2rem;

      img {
        width: 100% !important;
      }
      
    }
  }

  &__carousel {
    margin-top: 2rem;
  }

  &__tagline {
  
    display: block;
    margin: 0;
    font-size: 1.125rem;
    color: $color-text-muted;
    text-transform: uppercase;
    font-weight: 400;
    font-family: Klavika, sans-serif;
    letter-spacing: 0.04rem;

    @media (max-width: 640px) {
      &:first-child {
        margin-top: 2rem;
      }
    }
  }

  &__headline {
    font-size: 2rem;
    line-height: 1.375;
    font-family: Klavika, sans-serif;
    font-weight: normal;
    margin: 0.5rem 0 0;
    text-transform: uppercase;
  }

  &__intro {
    margin-top: 1rem;
    max-width: 45rem;

    p {
      font-size: 1rem;
      font-family: GestaSemiCondensed, gesta, sans-serif;
      font-weight: normal;
      line-height: 1.25;
    }
  }







}

.media-items {
  position: relative;
  list-style-type: none;
  padding: 0;
  display: flex;

}

.media-item {
  &__artwork {
    display: block;
    width: 100%;
    clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%)
  }

  &__url {
    display: block;
    text-decoration: none;
  }

  &__headline {
    color: #748A96;
    text-transform: uppercase;
    font-weight: 400;
    font-family: Klavika, sans-serif;
    margin: 1rem 0 0;
    font-size: 1rem;
    
  }

  .typography {


    p {
      margin-top: 0.5rem;
      font-size: 1rem;
      font-family: GestaSemiCondensed, gesta, sans-serif;
      font-weight: normal;
      line-height: 1.25;
    }
  }

  &__source {
    display: none;
  }
}