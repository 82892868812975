.footer {
  background: $color-bg-footer url(../../images/footer.webp) no-repeat;
  background-size: cover;
  background-position: bottom left;
  background-blend-mode: multiply;
  
  @media (max-width: 800px) {
    background-blend-mode: color-burn;

  }



  &__inner {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
    padding-bottom: 3rem;
    align-items: flex-end;

    @media (max-width: 800px) {
      display: block; 
      padding-bottom: 2rem;
      padding-top: 0rem;
    }


  }

  &-info {
    order: 2;
    overflow: hidden;

    &__nav {
      text-align: right;
      padding-right: 6.5rem;
      letter-spacing: .04rem;

      @media (max-width: 800px) {
        padding: 0;
        text-align: left;
      }
  

      a, button {
        appearance: none;
        background: none;
        border: 0;
        display: inline-block;
        margin-left: 3rem;
        color: $color-text-nav;
        text-transform: uppercase;
        text-decoration: none;
        font-size: .875rem;
        font-family: Klavika, sans-serif;
        cursor: pointer;

        @media (max-width: 800px) {
          margin-left: 0;
          margin-right: 1.75rem;
          margin-top: 2rem;
        }
      }
    }
    &__address {
      font-weight: 300;
      font-size: 1rem;
      color: $color-text-nav;
      float: right;
      font-style: normal;
      margin-top: 4rem;
      line-height: 1.25;

      p {
        margin: 0;
      }

      a {
        color: $color-text-nav;
      }

      @media (max-width: 800px) {
        float: none;
        margin-top: 2rem;
      }

    }

    &__logo {
      clear: right;
      width: 12rem;
      height: auto;
      display: block;
      float: right;
      margin-top: 4rem;

      @media (max-width: 800px) {
        position: absolute;
        bottom: 3rem;
        right: 1rem;
      }

      @media (max-width: 450px) {
        display: none;
      }
    }
  }

  &__social {
    order: 1;
    display: flex;
    align-items: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 800px) {
      margin-top: 2rem;
    }

    a {
      display: block;
      margin-right: 2rem;
      img {
        display: block;
        width: 2rem;
        height: auto;
      }
    }

  }


}



.above-footer {
  z-index: 1;
  margin-top: -1rem;
  pointer-events: none;

  @media (max-width: 1145px) {
    display: none;
  }
  img {
    display: block;
    margin: 0 auto -1rem;
    width: scale-size(92rem);


  }
}

.page--showroom-display .above-footer {
  display: none;
}


.footer-newsletter {

  padding-top: 4rem;

  @media (max-width: 1145px) {
    padding-bottom: 4rem;
  }

  &__layout {
    background: $color-bg-silver;
    padding: 4rem 1rem;
    text-align: center;
  }

  &__headline {
    font-size: 2.1rem;
    text-transform: uppercase;
    font-family: Klavika, Helvetica, Arial, sans-serif;
    font-weight: normal;
    margin: 0;
  }

  &__copy {
    margin-top: 2rem;
  }

  &__button {
    margin-top: 2rem;
    background: none;
    border: 0;
    color: $color-text-brand;
    text-transform: uppercase;
    font-size: 1rem;
    width: auto;
    cursor: pointer;

    &::before {
      display: block;
      margin: 1rem auto 1rem;
      content: '';
      width: 7.5rem;
      height: 7.5rem;
      border: .5rem solid #FFF;
      background: #FFF url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20data-name%3D%22Ebene%201%22%20viewBox%3D%220%200%20175.83%20158.73%22%3E%3Cpath%20fill%3D%22%235a595d%22%20d%3D%22M89.7%20154.31a2%202%200%200%201-1.53-.73c-.93-1.12-.43-2%20.31-2.79%201.47-1.21%201-5.41.11-8.22L0%2069.35%2084.52%200l89.17%2070.59.16.23a14.14%2014.14%200%200%201%201.92%208.87c-.38%204.9-4.94%208.21-5.75%208.75l-78.84%2065.23-.12.11H91a2.06%202.06%200%200%201-1.3.53zM6.26%2069.36L92%20140.21l.18.53a24.16%2024.16%200%200%201%201.17%206l74.42-61.55s3.79-2.51%204.05-5.79a10.76%2010.76%200%200%200-1.1-6.07L84.57%205.11z%22%2F%3E%3Cpath%20fill%3D%22%235a595d%22%20d%3D%22M79.36%20158.73a15.68%2015.68%200%200%201-9-2.71c-3-2.24-48.25-39.19-50.17-40.76l-1.87-1.52L37.6%2097.58l2.56%203-15.64%2013.12c8.78%207.17%2045.57%2037.19%2048.17%2039.13a12.24%2012.24%200%200%200%2013.56-.27c4.51-3.28%202.17-10.48%202.15-10.55l3.77-1.26c.14.41%203.26%2010.05-3.58%2015a15.58%2015.58%200%200%201-9.23%202.98zM61.359%2038.338L86.675%2017.2l2.55%203.056L63.91%2041.393zM71.92%2048.913l25.315-21.137%202.551%203.055L74.47%2051.968zm10.7%2010.707l25.317-21.138%202.55%203.055-25.315%2021.137zm-6.91%2048.655l59.105-48.411%202.522%203.079-59.104%2048.41zm11.488%209.398l59.105-48.411%202.522%203.079-59.105%2048.41zM65.682%2098.23l59.104-48.411%202.522%203.079-59.104%2048.41zM53.23%2095.44L20.11%2067.51l30-24.45%2033.67%2026.78zM26.34%2067.56l26.9%2022.68%2024.25-20.32-27.37-21.75z%22%2F%3E%3C%2Fsvg%3E);
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }
  }

  + .above-footer {
    display: block !important;

    @media (max-width: 1145px) {
      display: none !important;
    }
  }
}

.page--study {
  .footer-newsletter {

    padding-top: 0;

    .block__inner {
      max-width: calc(scale-size(116rem) - 10%);
    }
  }

}

.page--article {
  .footer-newsletter {

    padding-top: 8rem;

    .block__inner {
      padding-left: 0;
      padding-right: 0;
    }
  
  }
}