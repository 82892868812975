.talk {
    &__header {
        position: relative;

    }

    &__register-toggle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2rem;
    }

    &__form {
        position: relative;
        top: -1rem;

        iframe {
            transition: height linear .25s;
        }

        &--closed {
            iframe {
                height: 0 !important;
                overflow: hidden;
            }
        }
    }

    &__meta {
        padding-top: 1rem;
        border-top: 1px solid #6C7F87;
        margin-top: 2rem;
    }

}