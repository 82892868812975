.topic-previews {
  position: relative;
  z-index: 2;
  margin-top: 4rem;
  padding: 4rem 0 6rem;
  background: #E4ECEF;

  &-list {
    list-style: none;
    margin: 2rem 0 0;
    padding: 0;
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    grid-gap: 1rem;
    grid-row-gap: 3rem;

  }

  &-item {
    a {
      text-decoration: none;
      color: $color-text;
      display: block;

      &:hover .topic-previews-item__text {
        top: 0;
      }

      &:hover .topic-previews-item__top::after {

        opacity: .9;

      }
    }

    &__top {
      position: relative;
      clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 1rem), 0% 100%);
      overflow: hidden;

      &::after {
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;

        position: absolute;
        content: '';
        background: #2E3D3E;
        opacity: 0;

        transition: linear .25s opacity;

      }
    }

    &__logo {
      position: absolute;
      background: $color-bg-gunmetal;
      z-index: 3;
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
      height: 6rem;
      padding: .75rem 1rem 1.25rem;
      display: flex;
      overflow: hidden;
      align-items: center;
      clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 1rem), 0% 100%);
      color: #979E9F;
      font-family: Klavika, sans-serif;
      font-weight: normal;
      font-size: 1.5rem;

      img {
        max-height: 4rem;
        max-width: 85%;
        display: block;
        opacity: .7;

      }
    }

    &__image {
      width: 100%;
    }

    &__text {
      font-size: 85%;
      box-sizing: border-box;
      padding: 5rem 1rem 2rem;
      position: absolute;
      top: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      z-index: 2;
      display: flex;
      align-items: flex-end;

      color: #FFF;
      transition: top linear .25s;

      p {
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.05rem;
        line-height: 1.375;
        margin-top: 1rem;
        font-family: GestaSemiCondensed-Light, gesta, sans-serif;
      }


    }

    &__title {
      text-transform: uppercase;
      color: $color-text-muted;
      margin: 1rem 0 0;
      font-weight: 400;
      font-family: Klavika;
    }
  }
}