.page--about-us {
    background: #FFF;
}

.about-us {



    &__inner {
        @extend .block__inner;
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr;

    }

    &__top, &__bottom {
        border: 1px solid $color-border-block-red;

    }

    &__bottom {
        border-color: rgba($color-border-window-gray, .33333);
        background: $color-bg-body;
        grid-template-columns: 1.5fr 1fr;
    }
}

.about-us-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto auto auto;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
    }

    &__item {
        padding: 1rem;
        border: 1px solid rgba($color-border-window-gray, .33333);

        &--dark {
            background: $color-bg-body;
        }

        &:first-child {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        &:nth-child(2) {
            grid-column: 1 / 2;
            grid-row: 2 / end;
        }

        &:nth-child(3) {
            grid-column: 2 / end;
            grid-row: 1 / 3;
        }

        &:nth-child(4) {
            grid-column: 2 / end;
            grid-row: 3 / end;
        }
    }
}

.about-us-box {
    text-decoration: none;
    color: $color-text;
    padding: 2rem 1rem;
    display: grid;
    gap: 2rem;
    align-items: start;
    position: relative;


    h3 {
        color: $color-text-brand;
    }

    &__more {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        display: inline-block;
        width: 1rem;
        height: .875rem;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='40' height='40'%3E%3Cpath style='fill:%23DA2028' d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z'%3E%3C/path%3E%3C/svg%3E");
    }

    &__image {
        max-width: 100%;
        clip-path: polygon(0 0, 100% 4%, 100% 93.5%, 0% 100%);
    }

    &--dark {
        background: $color-bg-body;
    }    

    &--image-right {
        grid-template-columns: 1fr 1.5fr;
    }

    &--image-narrow {
        .about-us-box__image {
            max-width: 80%;
        }
    }

    &--image-left {
        grid-template-columns: 1.5fr 1fr;

        .typography {
            grid-column: 2/3;
            grid-row: 1/2;
        }

        .about-us-box--image {
            grid-column: 1/2;
            grid-row: 1/2;
        }
    }

    &--image-left, &--image-right {
        @media (max-width: 1024px) {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            
        }
    }

    &--image-top {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

}