.subnav-container--planer {
  margin: 0 auto;
}


.subnav--planer {
  padding: 1.5rem 0 1rem;
  border-bottom: 1px solid $color-border-window-gray;
  background: $color-bg-body;

  .subnav {
    &__inner {
      border: 0;
      padding-top: 0;
      padding-bottom: 0;

    }

    &--fixed {
      @media (min-width: 640px) {
        background: none;
      }
    }

  }

}



