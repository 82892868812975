.page--planer {
  background: #FFF;

  .above-footer {
    //display: none;
  }

  .block:last-child {
    margin-bottom: 4rem;
  }

  .block__inner {
    @media (max-width: 580px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.planer-visual {
  margin-top: 0;
}

