.header {

  &--planer {

    padding-bottom: 0;

    .header__top {
      padding-top: 2rem;
      padding-bottom: 2rem;

      display: flex;
      justify-content: space-between;
    }

  }

  &__section-logo {
    width: 14rem;
    margin-top: 2rem;
  }

  &__section-nav {
    display: flex;
    grid-gap: 2rem;
    align-self: flex-start;

    a {
      color: $color-text-nav;
      display: inline-block;
      font-size: .75rem;
      text-decoration: none;
      text-transform: uppercase;
      font-family: Klavika, sans-serif;
    }

  }

  &-planer {
    background: #2e3d3e;

    &__inner {
      padding: 2rem;
    }

    &__logo {
      max-width: 24rem;
    }
  }
}