.page--study {
  background: #FFF;
  .article {

    padding-top: 3rem;
    padding-bottom: 10rem;
  }

  .above-footer {
    display: none;
  }

  .typography--article p {
    line-height: 1.75;
  }

  .article-block {
    // max-width: scale-size(132rem);

    &-image {
      padding-left: 5%;
      padding-right: 5%;
    }

    &--image-before-text {
      grid-template-areas:"space image text space2";
      grid-template-columns: 15% 1fr 1.125fr 15%;

      @media (max-width: 1190px) {
        grid-template-columns: 0 1fr 1.125fr 0;
      }

      @media (max-width: 640px) {
        grid-template-areas:
        "space text  space2" 
        "space image space2";
        grid-template-columns: 0 1fr 0;
        grid-gap: 1rem;
      }
      }
    }

    &--image-after-text {
      grid-template-areas:"space text image space2";
      grid-template-columns: 15% 1.125fr 1fr 15%;

      @media (max-width: 640px) {
        grid-template-areas:
        "space image  space2" 
        "space text space2";
        grid-template-columns: 0 1fr 0;
        grid-gap: 1rem;
      }      
    }


}