$color-text-nav: #194554;
$color-text: #231F20;
$color-text-light: #FFF;
$color-text-muted: #748A96;
$color-text-button: #AABECA;
$color-text-button-default: #628595;
$color-text-brand: #D51317;
$color-text-map: #D51317;
$color-text-headline: #D51317;
$color-text-window-gray: #8B9FAA;
$color-text-mobile-nav: #707F87;

$color-bg-body: #f3f5f5;
$color-bg-gunmetal: #253A3C;
$color-bg-line: #6C7F87;
$color-bg-footer: #C6C6C6;
$color-bg-lightblue: #EDF2F5;
$color-bg-mystic: #E4ECEF;
$color-bg-heather: #AABFC9;
$color-bg-dark: #2e3d3e;
$color-bg-silver: #cbd6dc;

$color-border-block-red: #DA2028;

$color-border-form: #234554;
$color-border-window-gray: $color-text-window-gray;