//@import url("//hello.myfonts.net/count/3d7367");

@font-face {
  font-family: "AkkoRoundedPro";
  src: url('../../fonts/AkkoRoundedProRegular/font.woff2') format('woff2'), url('../../fonts/AkkoRoundedProRegular/font.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "AkkoRoundedPro";
  src: url('../../fonts/AkkoRoundedProThin/font.woff2') format('woff2'), url('../../fonts/AkkoRoundedProThin/font.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "AkkoRoundedPro";
  src: url('../../fonts/AkkoRoundedProMedium/font.woff2') format('woff2'), url('../../fonts/AkkoRoundedProMedium/font.woff') format('woff');
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: "AkkoRoundedPro";
  src: url('../../fonts/AkkoRoundedProLightItalic/font.woff2') format('woff2'), url('../../fonts/AkkoRoundedProLightItalic/font.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Klavika";
  src: url('../../fonts/Klavika/KlavikaWebBasicLight.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Klavika";
  src:  url('../../fonts/Klavika/KlavikaWebBasicRegular.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Klavika";
  src: url('../../fonts/Klavika/KlavikaWebBasicMedium.woff') format('woff');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'GestaSemiCondensed-Regular';
  src: url('../../fonts/Gesta/GestaSemiCondensed-Regular-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/GestaSemiCondensed-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'GestaSemiCondensed-Light';
  src: url('../../fonts/Gesta/GestaSemiCondensed-Light-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/GestaSemiCondensed-Light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-Regular-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-Italic-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-Italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;

}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-Medium-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-Medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-MediumItalic-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-MediumItalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-Light-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-Light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-LightItalic-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-LightItalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-Bold-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-Bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gesta';
  src: url('../../fonts/Gesta/Gesta-BoldItalic-webfont.woff2') format('woff2'),
       url('../../fonts/Gesta/Gesta-BoldItalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
