.page--studynet {
    background: #FFF;
    .above-footer {
        display: none;
    }
}

.studynet {


    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        padding-bottom: 1rem;
        padding-top: 3rem;
        padding-bottom: 10rem;

        &::before {
            content: '';
            width: 0;
            padding-bottom: 100%;
            grid-row: 1 / 1;
            grid-column: 1 / 1;
        }

        > *:first-child {
            grid-row: 1 / 1;
            grid-column: 1 / 1;
        }
    }
}

.study-preview {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover &__info {

        height: 10rem;
    }

    &:hover &__prose {

        opacity: 1;
    }

    @media (hover: none) {
        &__info {
            height: 8rem !important;
        }

        &__prose {
            opacity: 0 !important;
        }
    }

    &__img {
        position: absolute;
        bottom: 4.5rem;
        left: 0;
        right: 0;
        top: 0;
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__info {
        background: $color-bg-dark;
        position: absolute;
        height: 5.5rem;
        bottom: 0;
        left: 0;
        right: 0;

        transition: height cubic-bezier(0.075, 0.82, 0.165, 1) .25s;
    }

    &__headline {
        position: absolute;
        left: 0;
        right: 0;

        font-family: Klavika;
        color: #FFF !important;
        font-weight: 200;
        padding: 0;
        margin: 1rem;
        font-size: 1rem;

        max-height: 2.5rem;
        box-sizing: content-box;
        overflow: hidden;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        line-height: 1.25;
        letter-spacing: 0.05rem;
        text-transform: uppercase;

    }

    &__prose {
        position: relative;
        font-family: "Klavika";
        color: #FFF;
        padding: 0;
        margin: 0 1rem;
        opacity: 0;
        transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) .5s;
        font-size: 1rem;
        height: 4.75rem;

        display: -webkit-box;
        -webkit-line-clamp: 45;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        font-weight: 200;
        margin-top: 4rem;
        padding-right: 3.5rem;

        line-height: 1.25rem;

        p {
            color: #FFF;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0rem;
            top: 0;
            bottom: 0;
            width: 1.5rem;

            background: url(../../../images/chevron_right.svg) center right no-repeat;
            background-size: contain;
        }

    }

    &::after {
        content: '';
        background: #FFF;
        width: 10%;
        height: 10%;

        position: absolute;
        right: -5%;
        bottom: -5%;
        transform: rotate(45deg);
    }

}